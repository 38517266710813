.teacher-practice-page {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1002;
  animation: fadeIn 0.5s;
  background: var(
    --Linear-progress-dark,
    linear-gradient(180deg, #3a1587 9%, #490dc8 99.99%, #3703a4 100%)
  );

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .container {
    /* Use CSS Grid */
    display: grid;

    /* Define the layout: Header and content */
    grid-template-areas:
      'header'
      'main';

    /* Define the size of the rows */
    grid-template-rows: 100px auto;

    /* Define the size of the columns */
    grid-template-columns: 1fr;
  }

  .header {
    /* Place the header in the grid */
    grid-area: header;

    /* Styles for the header */
    // margin: 47px;
    color: white;
    position: fixed;
    width: 100%;
    z-index: 102;
    height: 100px;
    max-height: 100px;
    // z-index: 9999;
  }

  .main {
    flex: 1;
    /* Place the main content in the grid */
    grid-area: main;

    /* Styles for the main content */
    border-radius: 24px;
    margin: 47px;
    overflow: hidden;
    margin-top: 105px;

    .activity-blueberry-component {
      // padding-top: 54px;
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: #ffffff;
      z-index: 101;
      padding: 32px;

      .feedback-panel-quiz {
        height: inherit;
      }

      .activity-view_content {
        padding: 0 48px;
      }

      .activity-footer {
        padding-bottom: 0;

        .activity-button {
          margin-left: auto;
          margin-right: auto;
          width: 70%;
        }
      }

      .activity-view__scroll-mark {
        padding-bottom: 88px;
      }
    }
  }

  .myButton {
    color: white;
    padding: 10px 20px;
    background-color: blue;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease;
  }

  .container-fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;

    .container-fullscreen-child {
      display: flex;
      justify-content: right;
      align-items: center;
      z-index: 1000;

      .container-fullscreen-button {
        color: white;
        border: 1px #fff solid;
        padding: 3px;
        border-radius: 36px;
        font-size: 16px;
        cursor: pointer;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
        transition: all 0.3s ease;
        margin-top: 10px;
        margin-right: 10px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background-color: rgb(0 0 0 / 32%);
          transform: scale(1.05);
        }

        &:active,
        &:focus {
          background-color: transparent;
          transform: scale(1);
        }

        img {
          width: 16px;
          height: 16px;
          color: white;
        }
      }
    }
  }
}
@media screen and (orientation: portrait) {
  .teacher-practice-page {
    transform: rotate(90deg);
    width: 100vh;
  }
}
