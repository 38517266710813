  .map-level-counter {
    height: 100%;
    width: 36px;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .map-level-counter-img {
      width: 36px;
      filter: drop-shadow(1px 2px 0px rgba(0, 0, 0, 0.25));
    }

    .map-level-counter-text {
      position: absolute;
      top: -1px;
      left: 0;
      width: 36px;
      height: 40px;

      color: var(--Gray-scale-white, #FFF);
      text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.20);
      font-family: Quicksand, sans-serif;
      font-size: 20px;
      font-weight: 700;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
