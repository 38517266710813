.avatar_slider__container{
    width: 90%;
    padding: 30px 0;
}
.swiper{
    width: 100%;
}

.swiper-wrapper{
    align-items: center;
}

.swiper-button-next, .swiper-button-prev {
    color: #FFFFFF; /* Cambia el color de las flechas */
    width: 35px;    /* Cambia el ancho de las flechas */
    height: 35px;   /* Cambia la altura de las flechas */
    // background: rgba(255, 255, 255, 0.3);
    border-radius: 31px;
    border: 1px solid #FFF;
    display: flex;
    justify-content: center;
    background: #FFFFFF1A;
  }

  .swiper-button-next:hover, .swiper-button-prev:hover {
    background: #FFFFFF3A;
}
  
  .swiper-button-next::after, .swiper-button-prev::after {
    font-size: 15px; /* Change the size of the arrows */
  }
  
  .swiper-button-prev{
    left: 0;
  }

  .swiper-button-next{
    right: 0;
  }

  .avatar_slider__button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 42px;   

    .avatar_slider__button--available {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #5A34E1;
      ;
  
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 24px;
  
      min-width: 164px;
      min-height: 39px;
      width: fit-content;
      margin: 0 auto;
  
      background: #FFFFFF;
      border-radius: 18px;
  
      cursor: pointer;
    }
    
    .avatar_slider__button--default {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #AFB7C6;
      ;
  
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 24px;
  
      min-width: 164px;
      min-height: 39px;
      width: fit-content;
      margin: 0 auto;
  
      background: #F0F4F8;
      border-radius: 18px;
      box-shadow: none;
      cursor: pointer;
      // pointer-events: none;
    }
  }
