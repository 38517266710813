$font-path: '../assets/fonts';
$primary-color: #ff7272;
$primary-blueberry: #6363FC;
$secondary-color: #ffa700;
$terciary-color: #b28ae4;
$text-color: #7b87b5;


$white: #ffffff;
$red: #ff3d30;
$pink: #ff7272;
$darkPink: #a64153;
$orange: #ffae56;
$blue: #45dbf4;
$purple: #eac2ff;
$darkPurple: #b28ae4;
$green: #00e19a;
$greyBlue: #cadcff;
$lightYellow: #fffbbf;
$mediumYellow: #ffd276;
$darkYellow: #ffa700;

// Grays
$gray-0: #111111;
$gray-1: #333333;
$gray-2: #4f4f4f;
$gray-3: #828282;
$gray-4: #bdbdbd;
$gray-5: #e0e0e0;
$gray-6: #f2f2f2;

// Grays Teacher Practice
$gray-1-teacher-practice: #303949;
$blue-1-cover-teacher-practice: #3C3CBF;

// Buttons Shadow
$shadow-grey: #e0e0e0;
$shadow-darkGray: #828282;
$shadow-darkPink: #a64153;
$shadow-darkYellow: #ffa700;
$shadow-darkOrange: #f25700;
$shadow-darkRed: #ca1a0e;
$shadow-darkGreen: #2ba05c;
$shadow-darkBlue: #0098ff;
$shadow-darkGreyBlue: #8b9ad1;
$shadow-darkPurple: #b28ae4;

$shadow-inset-grey: rgba(224, 224, 224, 0.25);
$shadow-inset-darkGray: rgba(130, 130, 130, 0.25);
$shadow-inset-darkPink: rgba(166, 65, 83, 0.25);
$shadow-inset-darkYellow: rgba(255, 167, 0, 0.25);
$shadow-inset-darkOrange: rgba(242, 87, 0, 0.25);
$shadow-inset-darkRed: rgba(202, 26, 14, 0.25);
$shadow-inset-darkGreen: rgba(43, 160, 92, 0.25);
$shadow-inset-darkBlue: rgba(0, 152, 255, 0.25);
$shadow-inset-darkGreyBlue: rgba(139, 154, 209, 0.25);
$shadow-inset-darkPurple: rgba(178, 138, 228, 0.25);

// forms
$form-line-color: #9997AE;
$form-placeholder-color: #9997AE;

// Gradients

$gradient-grey: linear-gradient(
  90deg,
  #80879b 0.06%,
  #858ca0 5.14%,
  #a4afc2 42.29%,
  #b7c4d7 72.82%,
  #beccdf 92.77%
);
$gradient-blue: linear-gradient(270deg, #45dbf4 39.06%, #00a1fd 100%);
$gradient-red: linear-gradient(90deg, #c50003 1.93%, #ff3d30 75.67%);
$gradient-orange: linear-gradient(90deg, #ff6f1e 1.93%, #ffae56 69.76%);
$gradient-yellow: linear-gradient(
  90deg,
  #ffae56 1.96%,
  #ffca6a 49.99%,
  #ffde88 99.98%
);
$gradient-green: linear-gradient(90deg, #00c7a1 2.51%, #00ff91 99.99%);
$gradient-purple: linear-gradient(270deg, #eac2ff 39.06%, #cf92ff 86.04%);
$gradient-pink: linear-gradient(90deg, #F3586B 1.93%, #FDA1AC 69.76%);

// Activity Headers
$header-lightBlue: #caf5ff;
$practice-banner-lightPurple: #fbf2ff;
$practice-banner-lightblue: $header-lightBlue;

// Shadows
@mixin element-shadow {
  -webkit-filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.15));
  filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.15));
}

// Battery pieces
$battery-green: #94DB29;
