@import '../../../assets/styles/blueberry.scss';

.activity-blueberry-component {
  .feedback-box {
    @include activity-primary-font;
    padding: 24px 0;
    position: relative;
    margin-top: 50px;

    .feedback-box__blueberro-hint {
      position: absolute;
      left: 18px;
      top: -48px;
    }

    .feedback-box__box {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 24px;
      border-radius: 12px;
      gap: 12px;
      align-items: center;
      background-color: map-get($activity-colors, white-bb);
      z-index: 12;

      .feedback-box__content {
        flex: 1;
        width: 100%;
        display: flex;
        justify-content: space-around;
        gap: 10px;

        .lemonade-feedback-message {
          color: map-get($activity-colors, gray-01);
          p {
            margin-bottom: 7px;
          }
        }
      }

      &.type-correct {
        border: 3px solid map-get($activity-colors, green-bb);
        .feedback-box__blueberro-hint {
          display: none;
        }
      }

      &.type-incorrect {
        border: 3px solid map-get($activity-colors, red-bb);
        .feedback-box__blueberro-hint {
          display: none;
        }
      }

      &.type-neutral {
        border: 3px solid map-get($activity-colors, primary-medium);
      }

      .practice-button {
        margin-top: 12px;
      }
    }

    .feedback-box__content-audio {
      display: flex;
      padding: 8px;
      align-items: center;
      place-content: center;
      width: 36px;
      height: 36px;
      border-radius: 18px;
      z-index: 6;

      .speak-button svg {
        height: 100%;
        width: auto;

        path {
          fill: map-get($activity-colors, primary-medium);
        }
      }
    }
  }
}
