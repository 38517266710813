.meta-unit-panel {
  width: 284px;
  height: auto;
  background: var(--ui-bar-background);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);

  border-radius: 8px;
  position: absolute;
  z-index: 2;
  top: 64px;
  padding: 12px 14px 16px 12px;
  display: flex;
  flex-direction: column;

  .meta-unit-panel__blocked {
    display: flex;
    flex-direction: column;
    align-items: center;

    .meta-unit-panel__previous-image {
      padding: 0 1em 1em 1em;
  
      img {
        width: 100%;
        border-radius: 8px;
        border: 2px solid var(--ui-primary-color);
      }
    }
  }

  .meta-unit-panel__blocked-icon {
    svg path {
      fill: var(--ui-primary-color);
    }
  }

  .meta-unit-panel__blocked-title {
    font-family: 'Quicksand';
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: var(--ui-primary-color);
    text-align: center;
    margin: 14px 0 12px 0;
  }

  .meta-unit-panel__blocked-info {
    font-family: 'Quicksand';
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--ui-primary-color);
    text-align: center;
    margin-bottom: 24px;
  }

  .meta-unit-panel__previous-image {
    padding: 0 1em 1em 1em;

    img {
      width: 100%;
      border-radius: 8px;
      border: 1px solid var(--ui-primary-color);
    }
  }

  .meta-unit-panel__unit-title {
    background: #88A7B8;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 34px;

    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #1C292B;
    padding: 8px;
  }

  .meta-unit-panel__ranking {
    margin: 16px 0;
  }

  .meta-ranking__no-data {
    height: 95px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .meta-unit-panel__button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #FFAF22;
    border-radius: 18px;
    padding: 8px 24px;
    color: #1C292B;
    font-family: 'Quicksand';
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
    width: fit-content;
    margin: 0 auto;
  }
}