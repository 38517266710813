.map-bottom-bar {
  position: absolute;
  bottom: 60px;
  right: 0;
  z-index: 101;

  padding: 8px 0 8px 12px;
  display: flex;
  align-items: center;
  border-radius: 12px 0 0 12px;
  background: #5A34E1;
  box-shadow: -8px -5px 12px 0px rgba(0, 0, 0, 0.17) inset;
  transition: 0.2s ease-out all;


  &.map-bottom-bar--visible {
    right: 0;
    transition: 0.5s ease-out all;
  }

  &.map-bottom-bar--hidden {
    right: -234px;
    transition: 0.5s ease-out all;

    .map-bottom__button {
      transform: rotate(180deg);
    }
  }

  .map-bottom__button {
    position: absolute;
    left: -12px;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background: #5A34E1;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }

  .map-bottom-bar__wrapper {
    padding: 10px 26px;

    border-radius: 8px 0 0 8px;
    background: #3703A4;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .map-bottom-bar__birdeye-button,
  .map-bottom-bar__missions-button,
  .map-bottom-bar__pets-button {
    height: 56px;
    width: 56px;
    cursor: pointer;
    position: relative;
  }

  .map-bottom-bar__missions-tooltip,
  .map-bottom-bar__pets-tooltip {
    display: none;
    flex-direction: column;
    align-items: center;
    padding: 8px 12px;
    width: max-content;
    border-radius: 8px;
    background: #3703A4;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.10);
    color: #FFF;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    line-height: 16px;
    bottom: 88px;
    text-align: center;

    &:before {
      content: '';
      width: 12px;
      height: 12px;
      background: #3703A4;
      transform: rotate(45deg);
      position: absolute;
      bottom: -6px;
    }
  }

  .map-bottom-bar__missions-button {
    &:hover {
      .map-bottom-bar__missions-tooltip {
        display: flex;
        left: -42px;
        width: 140px;
      }
    }
  }

  .map-bottom-bar__pets-button {
    &:hover {
      .map-bottom-bar__pets-tooltip {
        display: flex;
        left: -50px;
        width: 160px;
      }
    }
  }

  .map-bottom-bar__missions-button-halo,
  .map-bottom-bar__pets-button-halo {
    height: 56px;
    width: 56px;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 0 0 3px #30FFB1;
    border-radius: 50%;
    animation: notificationHalo 5000ms infinite linear;
    opacity: 0;
  }

  .map-bottom-bar__missions-button-exclamation,
  .map-bottom-bar__pets-button-exclamation {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -1%;
    right: -12%;
    width: 20px;
    height: 20px;
    color: #ffffff;
    background-color: #ff0000;
    border-radius: 50%;
    box-shadow: 0px 10px 2px 0px rgba(0, 0, 0, 0.25);
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 14px;
    animation: notification 900ms infinite ease-in-out;

    &.map-bottom-bar__missions-button-exclamation--grouped {
      top: -10%;
      right: 94%;
    }
  }

  @keyframes notificationHalo {
    5% {
      opacity: 1;
    }

    10% {
      opacity: 0.5;
    }

    20% {
      opacity: 0;
    }
  }

  @keyframes notification {
    15% {
      top: -15%;
    }

    30% {
      top: -1%;
    }

    45% {
      top: -15%;
    }

    60% {
      top: -1%;
    }

    75% {
      top: -1%;
    }
  }
}
