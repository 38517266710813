@import '../../../assets//styles/blueberry.scss';

.activity-blueberry-component {
  .feedback-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 64px;
    border-width: 2px;
    border-style: solid;
    background-color: map-get($activity-colors, white-bb);
    border-color: map-get($activity-colors, red-bb);
    box-shadow: 0px 1px 4px 0px map-get($activity-colors, shadow-02);
    cursor: pointer;
    position: relative;
    z-index: 12;
    opacity: 1;

    div {
      display: flex;
    }

    &.with-text {
      @include activity-primary-font;
      width: fit-content;
      height: auto;
      color: map-get($activity-colors, red-bb);
      font-weight: 700;
      border-color: map-get($activity-colors, white-bb);
      padding: 12px 16px;
      gap: 8px;
      border-radius: 36px;
      background: map-get($activity-colors, white-bb);
      box-shadow: 0px 1px 4px 0px map-get($activity-colors, shadow-02);
    }

    &:active {
      border-color: map-get($activity-colors, red-light-01);
      background-color: map-get($activity-colors, red-light-02);
    }

    // Deshabilitado
    &.disabled {
      border-color: map-get($activity-colors, disabled-front) !important;
      background-color: map-get($activity-colors, disabled-back) !important;

      path {
        fill: map-get($activity-colors, disabled-back) !important;
      }
    }
  }
}
