@import '../../assets/styles/blueberry.scss';
.activity-blueberry-component {
  .activity-view {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &.activity-view_iphone {
      padding-bottom: 30px;
    }

    .activity-view_content {
      display: flex;
      flex-direction: column;
      position: relative;
      flex-grow: 1;
      overflow-x: hidden;
      overflow-y: auto;
      // padding: 0 25%;
      padding-bottom: 8px;

      .activity-view_content__with-audio-button {
        position: relative;
        .lemo-stimulus {
          > :first-child {
            padding-right: 50px; // Para colocar el icono de altavoz
          }
        }

        .activity-view__stimulus-audio-button {
          position: absolute;
          padding-top: 16px;
          right: 16px;
        }
      }

      .lemo-dialog.lemo-hints-dialog {
        display: none;
      }

      .lemo-feedback-dialog {
        // [data-testid='individualFeedback'],
        [data-testid='globalFeedback'] {
          display: none;
        }
      }
    }

    .activity-view_top-scroll-shadow {
      position: relative;
      top: 0;
      width: 100%;
      height: 8px;
      min-height: 8px;
      margin-bottom: -8px;
      background: linear-gradient(
        to bottom,
        map-get($activity-colors, gray-03),
        map-get($activity-colors, gray-07)
      );
      opacity: 0.3;
      z-index: 13;
    }

    .activity-view_bottom-scroll-shadow {
      position: relative;
      bottom: 0;
      width: 100%;
      height: 8px;
      min-height: 8px;
      margin-top: -8px;
      background: linear-gradient(
        to top,
        map-get($activity-colors, gray-03),
        map-get($activity-colors, gray-07)
      );
      opacity: 0.3;
      z-index: 13;
    }

    @keyframes bounce-scroll-mark {
      0%,
      100% {
        transform: translate(-50%, 0);
      }
      10% {
        transform: translate(-50%, -20px);
      }
      20% {
        transform: translate(-50%, 10px);
      }
      30% {
        transform: translate(-50%, -10px);
      }
      40% {
        transform: translate(-50%, 5px);
      }
      50% {
        transform: translate(-50%, -5px);
      }
      60% {
        transform: translate(-50%, 2.5px);
      }
      70% {
        transform: translate(-50%, -2.5px);
      }
      80% {
        transform: translate(-50%, 1.25px);
      }
      90% {
        transform: translate(-50%, -1.25px);
      }
    }

    .activity-view__scroll-mark {
      position: fixed;
      left: 50%;
      padding-bottom: 16px;
      transform: translateX(-50%);
      z-index: 13;
      animation: bounce-scroll-mark 1.5s ease-in-out 0.5s;
      cursor: pointer;

      svg {
        height: 50px;
        width: auto;
      }
    }

    .activity-view__feedback-button-wrapper {
      z-index: 13;
      padding-top: 16px;
    }

    .number-question {
      position: absolute;
      transform: translateX(-44px) translateY(21px);
      background-color: #f0f4f8;
      padding: 10px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      span {
        text-align: center;
        color: #4d586c;
      }
    }
  }

  .main {
    .activity-view {
      .activity-view__scroll-mark {
        padding-bottom: calc(16px + 47px);
      }
    }
  }
}
