.quiz-podium {
  // Altura de la caja de resultados: padding + fuente + padding + padding + fuente + padding + padding
  $results-height: calc(24px + 14px + 10px + 36px + 30px + 36px + 24px);

  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  // background: center / cover no-repeat url('../../assets/backgorund_podium.svg');
  background: linear-gradient(180deg, #3a1587 9%, #490dc8 99.99%, #3703a4 100%);
  z-index: 15;

  .quiz-podium__wrapper {
    height: 100%;
    width: fit-content;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;

    .quiz-podium__title {
      color: #fff;
      font-family: Inter;
      font-size: 45px;
      font-weight: 700;
      padding-bottom: 8px;
    }

    .quiz-podium__body {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .quiz-podium__podium_star {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        padding-bottom: 5%;

        transform: scale(0);
        opacity: 0;
        animation: starAppear 0.5s ease 3s forwards;

        img {
          height: 86px;
        }
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-61%, -82%);
          font-family: Poppins, sans-serif;
          color: #3703a4;
          font-weight: 700;
          font-size: 32px;
        }
      }

      .quiz-podium__podium_scene_wrapper {
        .quiz-podium__podium_scene_avatar {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: relative;
          margin-top: 11%;

          .podium_mount {
            position: relative;
            z-index: 1;

            // La altura es igual al alto de la caja de resultados
            max-height: $results-height;
            height: 27vh;
            top: min(27vh, $results-height);
            animation: mountUp 0.5s ease 1s forwards;
          }

          .podium_avatar_wrapper {
            position: absolute;
            height: 80%;
            top: -400%;
            z-index: 2;
            animation: avatarDown 0.5s ease 2s forwards;

            @keyframes pulsePodium {
              0% {
                transform: translate(-50%, -50%) scale(0.95);
              }

              70% {
                transform: translate(-50%, -50%) scale(1.05);
              }

              100% {
                transform: translate(-50%, -50%) scale(0.95);
              }
            }

            .podium_avatar {
              position: absolute;
              height: 76%;
              z-index: 3;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              animation: pulsePodium 2s infinite 2s;
            }

            .podium_bubble {
              position: absolute;
              height: 100%;
              z-index: 3;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              animation: pulsePodium 2s infinite 2s;
            }
          }
        }
      }

      .quiz-podium__results {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        background: #5a34e1;
        font-family: Poppins;
        font-weight: 700;
        color: #fff;
        gap: 24px;
        padding: 24px;
        border-radius: 24px;
        z-index: 2;

        .quiz-podium__results_points,
        .quiz-podium__results_questions {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 50%;
          align-items: stretch;
          text-align: center;
          gap: 10px;
        }

        .quiz-podium__results_points_text,
        .quiz-podium__results_questions_text {
          text-transform: uppercase;
          white-space: nowrap;
          font-size: 14px;
        }

        .quiz-podium__results_points_user_points,
        .quiz-podium__results_questions_user_questions {
          padding: 36px 73px;
          white-space: nowrap;
          border-radius: 14px;
          background: rgba(0, 0, 0, 0.38);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          overflow: hidden;
          font-size: 30px;
          font-weight: 700;
        }
      }
    }

    .quiz-podium__button_exit {
      cursor: pointer;
      display: inline-flex;
      padding: 12px 32px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 36px;
      border: 2px solid #5a34e1;
      background-color: #fff;
      color: #5a34e1;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin-top: 8px;

      width: 74%;
    }
    .quiz-podium__button_exit:active {
      color: #3703a4;
      border-color: #3703a4;
    }
  }

  @keyframes starAppear {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    80% {
      transform: scale(1.2);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes mountUp {
    from {
      top: min(
        27vh,
        $results-height
      ); /* Esta es la posición de inicio. Ajusta este valor según tus necesidades. */
    }
    to {
      top: 0; /* Esta es la posición final. Ajusta este valor según tus necesidades. */
    }
  }

  @keyframes avatarDown {
    from {
      top: -400%;
    }
    to {
      top: -30%;
    }
  }
}
