.map-loading_page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.map-loading_background {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-image: url(./assets/LoadingBackground.png);

  @keyframes loadingPulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.05;
    }
    100% {
      opacity: 1;
    }
  }
}
.map-loading_container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.map-loading_header {
  flex: 1;
}
.map-loading_logo {
  flex: 1;
  margin-top: 32px;
}
.map-loading_footer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.map-loading_avatars {
  position: relative;
  height: 100vh;
  width: 100vw;
}
.map-loading_avatars-top-left {
  position: absolute;
  top: 0;
  left: 0;
}
.map-loading_avatars-top-right {
  position: absolute;
  top: 0;
  right: 0;
}
.map-loading_avatars-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}
.map-loading_avatars-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}
.map-loading_avatars-top-left-purple {
  position: absolute;
  left: 288px;
  top: 56px;
}
.map-loading_avatars-top-left-blue {
  position: absolute;
  left: 100px;
  top: 50px;
  z-index: 1;
}
.map-loading_avatars-top-left-moon {
  position: absolute;
  width: 180px;
  height: 170px;
  top: 168px;
  left: 100px;
}
.map-loading_avatars-bottom-left-scissors {
  position: absolute;
  bottom: 44px;
  left: 118px;
  z-index: 1;
}
.map-loading_avatars-bottom-left-green {
  position: absolute;
  bottom: 169px;
  left: 62px;
}
.map-loading_avatars-bottom-right-orange {
  position: absolute;
  bottom: 65px;
  right: 160px;
  z-index: 1;
}
.map-loading_avatars-bottom-right-blueberro {
  position: absolute;
  width: 162px;
  height: 162px;
  bottom: 180px;
  right: -6px;
}
.map-loading_avatars-bottom-right-other-planet-full-screen {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.map-loading_avatars-bottom-right-other-planet-horizonral-tablet {
  display: none;
}
.map-loading_avatars-top-right_red {
  position: absolute;
  top: 36px;
  right: 50px;
  z-index: 1;
}
.map-loading_avatars-top-right_planet {
  position: absolute;
  top: 75px;
  right: 80px;
}
.map-loading_avatars-bottom-left-rocket {
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 0;
}
.map-loading_avatars-bottom-right-other-planet-vertical-tablet {
  display: none;
}
.map-loading_avatars-bottom-right-other-planet-horizontal-tablet {
  display: none;
}
.map-loading_avatars-bottom-right-other-planet-horizontal-tablet-small {
  display: none;
}
.map-loading_footer-progress-bar_background {
  border-radius: 28px;
  background: var(--Grays-white, #fff);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25),
    0px 0px 14px 0px rgba(0, 0, 0, 0.25) inset;
  width: 350px;
  height: 35px;
  flex-shrink: 0;
  padding: 3px 3px 3px 3px;
}
.map-loading_footer-fill-progress-bar {
  border-radius: 23px;
  background: var(
    --Linear-progress,
    linear-gradient(180deg, #7c3de5 22.56%, #3c3cbf 100%)
  );
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25) inset;
  height: 100%;
  flex-shrink: 0;
  width: 240px;
  max-width: 343px;
}
.map-loading_footer-text {
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  padding-bottom: 16px;
  padding-top: 35px;
}
@media screen and (max-width: 1024px) {
  .map-loading_avatars-top-left-moon {
    width: 144px;
    height: 180px;
    top: 126px;
    left: 48px;
  }
  .map-loading_avatars-top-left-purple {
    left: 270px;
    top: 105px;
  }
  .map-loading_avatars-bottom-right-blueberro {
    bottom: 320px;
    right: 18px;
  }
  .map-loading_avatars-bottom-right-orange {
    bottom: 118px;
    right: 100px;
  }
  .map-loading_avatars-bottom-left-rocket {
    left: -120px;
  }
  .map-loading_avatars-bottom-left-scissors {
    bottom: 200px;
    left: 10px;
  }
  .map-loading_avatars-bottom-left-green {
    bottom: 332px;
    left: 54px;
  }
  .map-loading_avatars-bottom-right-other-planet-full-screen {
    display: none;
  }
  .map-loading_avatars-bottom-right-other-planet-horizontal-tablet {
    display: block;
  }
  .map-loading_avatars-top-right_red {
    right: 8px;
  }
  .map-loading_logo {
    padding-top: 100px;
  }
  .map-loading_avatars-top-right_planet {
    top: 60px;
    right: -8px;
  }
  .map-loading_avatars-bottom-right-other-planet-vertical-tablet {
    display: none;
  }
  .map-loading_avatars-bottom-right-other-planet-vertical-tablet-small {
    display: none;
  }
  .map-loading_avatars-bottom-right-other-planet-horizontal-tablet-small {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .map-loading_avatars-top-left-blue {
    left: 95px;
    top: 56px;
  }
  .map-loading_avatars-top-left-purple {
    top: 180px;
    width: 61px;
    height: 61px;
  }
  .map-loading_avatars-top-right_planet {
    right: -28px;
  }
  .map-loading_avatars-top-right_red {
    right: -12px;
  }
  .map-loading_avatars-bottom-left-green {
    bottom: 410px;
    left: 10px;
    width: 114px;
    height: 114px;
  }
  .map-loading_avatars-bottom-left-rocket {
    bottom: 178px;
    left: -103px;
    width: 388px;
  }
  .map-loading_avatars-bottom-left-scissors {
    bottom: 44px;
    left: 80px;
    width: 84px;
    height: 84px;
  }
  .map-loading_logo {
    padding-top: 0px;
  }
  .map-loading_avatars-bottom-right-orange {
    bottom: 50px;
    right: 24px;
  }
  .map-loading_avatars-bottom-right-blueberro {
    bottom: 310px;
    right: 65px;
    width: 134px;
    height: 134px;
  }
  .map-loading_avatars-bottom-right-other-planet-horizontal-tablet {
    display: none;
  }
  .map-loading_avatars-bottom-right-other-planet-vertical-tablet {
    display: block;
  }
  .map-loading_footer-progress-bar_background {
    padding-top: 0px;
  }
  .map-loading_avatars-bottom-right-other-planet-vertical-tablet-small {
    display: none;
  }
  .map-loading_avatars-bottom-right-other-planet-horizontal-tablet-small {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .map-loading_avatars-top-left-moon {
    top: 100px;
    left: -54px;
  }
  .map-loading_avatars-top-left-blue {
    left: 14px;
    top: 30px;
  }
  .map-loading_avatars-top-left-purple {
    left: 142px;
  }
  .map-loading_avatars-top-right_planet {
    right: -35px;
  }
  .map-loading_avatars-top-right_red {
    right: 5px;
    top: 87px;
    width: 155px;
    height: 155px;
  }
  .map-loading_avatars-bottom-left-scissors {
    bottom: 40px;
    left: 40px;
  }
  .map-loading_avatars-bottom-left-green {
    bottom: 378px;
    left: 0px;
  }
  .map-loading_avatars-bottom-left-rocket {
    bottom: 191px;
    left: -118px;
  }
  .map-loading_avatars-bottom-right-blueberro {
    bottom: 290px;
    right: 20px;
  }
  .map-loading_avatars-bottom-right-other-planet-vertical-tablet {
    display: none;
  }
  .map-loading_avatars-bottom-right-orange {
    width: 105px;
    height: 105px;
  }
  .map-loading_footer-progress-bar_background {
    margin-top: -56px;
  }
  .map-loading_footer-text {
    margin-top: 10px;
  }
  .map-loading_avatars-bottom-right-other-planet-vertical-tablet-small {
    display: block;
  }
  .map-loading_footer-progress-bar_background {
    margin-top: -70px;
  }
  .map-loading_avatars-bottom-right-other-planet-horizontal-tablet-small {
    display: none;
  }
}
@media screen and (max-height: 600px) {
  .map-loading_avatars-top-left-moon {
    left: -40px;
    top: 60px;
  }
  .map-loading_avatars-top-left-blue {
    left: 52px;
    top: 23px;
    z-index: 1;
  }
  .map-loading_avatars-top-left-purple {
    left: 238px;
    top: 70px;
  }
  .map-loading_avatars-bottom-left-scissors {
    bottom: 5px;
    left: 93px;
    width: 90px;
  }
  .map-loading_avatars-bottom-left-green {
    bottom: 180px;
    left: 34px;
    width: 122px;
    height: 122px;
  }
  .map-loading_logo {
    padding-top: 0px;
  }
  .map-loading_avatars-bottom-left-rocket {
    left: -50px;
    width: 339px;
  }
  .map-loading_avatars-top-right_red {
    width: 155px;
    top: 90px;
    right: 110px;
  }
  .map-loading_avatars-top-right_planet {
    top: 30px;
    right: 30px;
  }
  .map-loading_avatars-bottom-right-other-planet-horizontal-tablet {
    display: none;
  }
  .map-loading_avatars-bottom-right-blueberro {
    bottom: 160px;
    width: 143px;
  }
  .map-loading_footer-progress-bar_background {
    margin-top: -50px;
  }
  .map-loading_avatars-bottom-right-other-planet-horizontal-tablet-small {
    display: block;
  }
  .map-loading_avatars-bottom-right-orange {
    bottom: 50px;
    right: 176px;
    width: 112px;
  }
}
@media screen and (max-height: 599px) {
  .map-loading_avatars-bottom-right-other-planet-vertical-tablet-small {
    display: none;
  }
}
