.map-items-counter {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-left: 16px;

  .map-items-counter__background {
    border-radius: 40px;
    background: var(--Blueberry-Primary-Dark-blue, #3703A4);

    height: 25px;
    min-width: 48px;
    padding: 4px 10px;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    .map-items-counter__icon {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      position: absolute;
      left: -16px;

      svg {
        width: 34px;
        height: 34px;
      }
    }

    .map-items-counter__count {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-end;
      margin-left: 12px;
      color: #FFFFFF;
      text-align: right;

      font-family: Quicksand, sans-serif;
      font-size: 14px;
      font-weight: 700;
    }
  }


}
