.reward-daily-goal {
  position: relative;

  .reward-popup__title p {
    margin: 0;
  }

  .reward-popup__subtitle {
    margin: 10px 0 30px 0;
    color: #FFF;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .reward-popup__image {
    margin: 30px 0 50px 0;
  }
}

.reward-daily-goal__ribbon {
  margin-top: -28px !important;
}

.reward-daily-goal__text {
  margin-top: -62px;
  color: #FFF;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  font-family: Quicksand, sans-serif;
  font-size: 36px;
  font-weight: 700;
}

.reward-daily-goal__streak-cards {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reward-daily-goal__streak-progress {
  display: flex;
  justify-content: center;
}

.reward-daily-goal__actions {
  margin-top: 24px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
}

.reward-daily-goal__button {
  display: flex;
  padding: 8px 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: #FFAF22;
  color: #1C292B;
  text-align: center;
  font-family: Quicksand, sans-serif;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}
