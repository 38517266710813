@import '../../../theme/index.scss';

:root {
  --ui-bar-background: rgba(28, 41, 43, 0.75);
  --ui-primary-color: #ffffff;
  --ui-switch-on-color: #ffffff;
  --ui-switch-off-color: #ffffff;
  --ui-switch-button-color: #5A34E1;
  --ui-progress-bar-background: #ffffff;
  --ui-modal-title-color: #ffaf22;
  --ui-modal-subtitle-color: #ffffff;
  --ui-modal-header-text-color: #ffffff;
  --ui-modal-content-text-color: #ffffff;
  --ui-modal-close-color: rgba(242, 242, 242, 0.85);
  --ui-tab-text-color: #ffffff;
  --ui-ranking-more-color: #ffaf22;
  --ui-charge-fill-color: #384346;
  --ui-charge-stroke-color: #576b70;
  --ui-modal-strong-color: #ffaf22;
}

.metaberry-view__phaser_loading {
  opacity: 0;
}

.metaberry-view {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: #000000;

  .phaser-game {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: opacity 1s ease;

    canvas {
      width: 100%;
      height: 100%;
    }
  }

  .metaberry-view__activity_on {
    opacity: 0 !important;
    z-index: -1 !important;
  }

  .metaberry-view__intro_on {
    transition: opacity 1s ease;
    opacity: 0 !important;
    z-index: -1 !important;
  }

  // Responsive top bar

  @media (max-width: 1140px) {
    .metaberry-view__user-level {
      .meta-experience-progress-bar {
        width: 150px;
      }
    }

    .metaberry-view__daily-goal {
      .meta-daily-goal-bar__bar-container {
        width: 220px;
      }
    }
  }

  @media (max-width: 876px) {
    .metaberry-view__user-level {
      .meta-experience-bar__progress {
        display: none;
      }

      .meta-experience-level {
        margin-left: 6px;
      }
    }

    .metaberry-view__daily-goal {
      .meta-daily-goal-bar__bar-container {
        width: 280px;
      }
    }
  }

  @media (max-width: 785px) {
    .metaberry-view__user-level {
      .meta-experience-bar__progress {
        display: none;
      }

      .meta-experience-level {
        margin-left: 6px;
      }
    }

    .metaberry-view__daily-goal {
      .meta-daily-goal-bar__bar-container {
        width: 190px;
      }
    }
  }
}
