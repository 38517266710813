 .shop-level {
   border-radius: 4px;
   padding: 3px 10px;

   font-family: Quicksand, sans-serif;
   font-weight: 700;
   font-size: 14px;
   line-height: 20px;
   color: #ffffff;
   text-transform: uppercase;
   display: flex;
   align-items: center;
   justify-content: center;

   span {
     display: inline-block;
     font-size: 16px;
     margin-left: 8px;
     margin-right: 2px;
   }
 }

 .shop-level--default {
   border: 2px solid #C3D3DB;
   background: #576B70;
 }

 .shop-level--locked {
   border: 2px solid #B94141;
   background: #EB5656;
 }
