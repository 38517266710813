.activity-blueberry-component {
  // Stimulus
  .regular-practice .lemonade-exercises .lemo-question-header {
    background: #fbf2ff;
    border-radius: 32px;
    padding: 16px 64px 22px 36px;
    position: relative;
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 16px;

    // Estilos por defecto en tablas para editores
    .lemo-stimulus {
      table,
      table td,
      table th {
        text-align: center;
      }
    }
  }

  .lemonade-exercises {
    table,
    table td,
    table th {
      text-align: center;
    }

    height: auto;
    width: 100%;
  }

  // LEMONADE TOKENS
  .lemonade-exercises.lemo-color-tokens {
    --lemo-color-base-background: #fafafa;
    --lemo-color-base-white: #ffffff;
    --lemo-color-base-black: #000000;
    --lemo-color-background-overlay: rgba(0, 0, 0, 0.5);
    --lemo-color-gray-01: #f2f2f2;
    --lemo-color-gray-02: #e0e0e0;
    --lemo-color-gray-03: #bdbdbd;
    --lemo-color-gray-04: #828282;
    --lemo-color-gray-05: #4f4f4f;
    --lemo-color-gray-06: #303949;
    --lemo-transitions: 250ms;
    --lemo-color-corporate: #5a34e1;
    --lemo-color-corporate-hover: #3c3cbf;
    --lemo-color-corporate-light: #acacf7;
    --lemo-color-corporate-light-alpha: #acacf726;
    --lemo-color-corporate-lighter: #f0f7ff;
    --lemo-color-corporate-dark: #3e249d;
    --lemo-color-error: #e41515;
    --lemo-color-error-light: #ffebea;
    --lemo-color-error-light-alpha: #ffecea80;
    --lemo-color-success: #00e19a;
    --lemo-color-success-light: #e5fcf5;
    --lemo-color-success-light-alpha: #e5fcf580;
    --lemo-color-transparent: transparent;
    --lemo-color-blockquote-border: lightblue;
    --lemo-color-blockquote-background: aliceblue;
    --lemo-color-digital-clock: #9ed0bc;
  }

  .lemonade-exercises.lemo-text-tokens {
    --lemo-color-text-01: var(--lemo-color-gray-06);
    --lemo-color-text-02: var(--lemo-color-gray-05);
    --lemo-color-text-03: var(--lemo-color-gray-04);
    --lemo-color-text-04: var(--lemo-color-gray-03);
    --lemo-color-text-white: var(--lemo-color-base-white);
    --lemo-color-text-placeholder: var(--lemo-color-gray-03);
    --lemo-text-bold: 600;
    --lemo-text-medium: 500;
    --lemo-text-regular: 400;
    --lemo-text-h1: 2.375em;
    --lemo-text-h2: 2em;
    --lemo-text-h3: 1.5em;
    --lemo-text-body-l: 1.125em;
    --lemo-text-body-m: 1em;
    --lemo-text-body-s: 0.875em;
    --lemo-text-body-xs: 0.75em;
    --lemo-line-height: 1.9;
    --lemo-font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }

  .lemonade-exercises.lemo-other-tokens {
    --lemo-radii-01: 1em;
    --lemo-radii-02: 0.5em;
    --lemo-radii-03: 0.7em;
    --lemo-radii-04: 0.3em;
    --lemo-opacity-disabled: 0.4;
  }

  // Customization
  .lemonade-exercises .lemo-text-body-l-regular,
  .lemonade-exercises .lemo-text-body-m-regular,
  .lemonade-exercises .lemo-text-body-s-regular,
  .lemonade-exercises .lemo-text-body-xs-regular {
    font-weight: var(--lemo-text-medium);
  }

  .lemonade-exercises .lemo-classify-classification .lemo-classification-th-content {
    font-weight: var(--lemo-text-medium);
    text-align: center;
  }

  .lemonade-exercises .lemo-multiple-choice .lemo-option .lemo-block-option.lemo-option-content-wrapper.lemo-touched {
    background-color: var(--lemo-color-corporate-lighter);
    border-color: var(--lemo-color-corporate);
  }

  .lemonade-exercises .lemo-match-list-mobile-question .lemo-text {
    color: var(--lemo-color-text-02);
    font-weight: var(--lemo-text-medium);
  }

  .lemonade-exercises .lemo-number-line__labels .lemo-number-line-label > * {
    border-radius: var(--lemo-radii-02);
  }

  .lemonade-exercises .lemo-chart-table thead th:first-child .lemo-item-header {
    border-radius: var(--lemo-radii-02) 0 0 0;
  }

  .lemonade-exercises .lemo-chart-table thead th:last-child .lemo-item-header {
    border-radius: 0 var(--lemo-radii-02) 0 0;
  }

  .lemonade-exercises .lemo-horizontal-chart-table tr:first-child th .lemo-item-header {
    border-radius: var(--lemo-radii-02) 0 0 0;
  }

  .lemonade-exercises .lemo-horizontal-chart-table tr:last-child th .lemo-item-header {
    border-radius: 0 0 0 var(--lemo-radii-02);
  }

  .lemonade-exercises .lemo-keyboard-button {
    border-radius: var(--lemo-radii-02);
  }

  .lemonade-exercises .lemo-mathField-background {
    border-radius: var(--lemo-radii-02);
  }

  // Fixes estilos theme Lemonade antiguos
  .lemonade-exercises {
    // Fix ancho completo
    .lemo-base-option .lemo-base-option__wrapper-html {
      width: 100%;
    }

    //Fix etiqueta superpuesta con imágen (para evitarlo)
    .lemo-math-cloze-math > .lemo-question-body .lemo-graphie-container > .lemo-graphie > .lemo-graphie-label {
      line-height: 1.5;
    }

    // hide lemonade hint
    .lemo-inline-feedback,
    .lemo-dialog.lemo-hints-dialog {
      display: none;
    }

    //  variantes
    .multiple-choice-table-fullwidth .lemo-base-option .lemo-base-option__wrapper-html {
      width: 100%;
      text-align: center;
    }
  }

  .fr-math,
  .fr-math-v2 {
    opacity: 1;
  }

  .no-break,
  .fr-no-break {
    display: inline-block;
    white-space: nowrap;
  }

  // ocultar botón de tabulación del teclado científico
  .ML__keyboard.apple.is-visible .keyboard-layer > div.rows > ul:last-child > li:last-child {
    visibility: hidden;
  }

  // fix estilos de tablas de froala
  .lemonade-exercises .html-content.fr-view td {
    padding: inherit !important;
    line-height: inherit !important;
  }

  // quitar los hyphens
  .lemonade-exercises .html-content.fr-view {
    hyphens: none !important;
  }

  // Pathway options in left side fix
  .lemonade-exercises .lemo-other-pathway {
    // Alinear tablero e instrucciones
    .lemo-question-body {
      align-items: center;
    }

    // Prevenir que al encoger se vayan haciendo círculos los cuadrados del camino
    .lemo-pathway-cell__bg {
      border-radius: 5%;
    }

    // Fix quitar configuración en mobile
    @media only screen and (min-width: 780px) {
      .lemo-question-body.lemo-layout-orientation-top {
        flex-direction: row;
        margin: 2em auto 0;

        .lemo-pathway-instructions {
          margin: 1em;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

  // fix cálculo del ancho de inputs
  .lemonade-font-preload {
    font-family: $lemonade-secondary-font;
    font-weight: bold;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
  }

  // posición input math cloze centrado
  .lemonade-exercises .lemo-mathField {
    display: flex;
    align-items: center;
  }

  // etiquetas en gráficos en una sola línea
  .lemonade-exercises .lemo-graphie-container .lemo-graphie-label {
    white-space: nowrap;
  }

  // fix temporal para posición de labels en charts (fix disponible en lemonade en futura versión)
  .lemonade-exercises .ct-chart {
    position: relative;
  }

  .lemonade-exercises .ct-chart-pie .ct-label {
    font-size: 1em;
  }

  // fix de posición del boliche cuando haces drag en number line
  .lemonade-exercises .lemo-math-number-line {
    .lemo-number-line__bullet {
      position: absolute !important;
    }
  }

  // fix tamaño números en fracciones
  .lemo-fraction-value {
    line-height: 1;
  }

  // Contenido multiple choice centrado
  .lemonade-exercises .lemo-multiple-choice .lemo-option .lemo-block-option.lemo-option-content-wrapper {
    text-align: center;
  }

  // Fix gráficas ancho total en mobile
  .lemonade-exercises .lemo-input-chart-view {
    margin: 0 auto;
  }

  // Fix más contraste en los gráficos de fracciones
  :root {
    --fractional-shape-background: #e1e1e1;
    --fractional-shape-fill: #4f4f4f;
    --fractional-shape-border-color: #fff;
    --fractional-shape-border-width: 1px;
  }

  // Fix gráficas centradas
  .lemonade-exercises .fr-chart,
  .lemonade-exercises .fr-fractional-shape {
    text-align: center;
  }

  // Fix espaciado de párrafos en enunciado
  .lemonade-exercises .lemo-stimulus p,
  .lemonade-exercises .lemo-title p {
    margin: 5px 0 15px 0;
  }

  // Fix gráficas se pisan
  .lemonade-exercises .lemo-pictograph-view .lemo-pictograph__column-label {
    line-height: 1.3em;
  }

  .lemonade-exercises .lemo-pictograph-view {
    margin: 2em 1em 3.5em 1em;
  }

  // Fix tipografía en digital clock
  // digital clock
  .lemonade-exercises .lemo-clock-digital-digits {
    .lemo-clock-digital-digits__digits {
      font-family: DS-Digital, sans-serif;
      letter-spacing: 0px;
      text-transform: none;
      font-size: 8.125em;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      color: rgb(158, 208, 188);
      line-height: 0.7;
      pointer-events: none;
    }
  }

  .lemonade-exercises .lemo-clock-digital .lemo-clock-digital__two_points {
    font-size: 8.125em;
  }

  // fix margen en math cloze
  .lemonade-exercises .lemo-math-cloze-math .lemo-blank {
    margin: 0 4px;
  }

  @media screen and (min-width: 760px) {
    .lemonade-exercises .lemo-clock-digital-digits {
      .lemo-clock-digital-digits__digits {
        font-size: 11.5em;
      }
    }

    .lemonade-exercises .lemo-clock-digital .lemo-clock-digital__two_points {
      font-size: 11.5em;
    }

    .lemonade-exercises .lemo-clock-digital-digits {
      .lemo-clock-digital-digits__digits {
        font-family: DS-Digital, sans-serif;
        letter-spacing: 0px;
        text-transform: none;
        font-size: 11.5em;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        color: rgb(158, 208, 188);
        line-height: 0.7;
        pointer-events: none;
      }
    }
  }

  // fix añadir parpadeo en drag and drop
  .lemonade-exercises .lemo-cloze-drag .lemo-drop-item.lemo-is-highlighted .lemo-drop-area {
    outline: 2px solid var(--lemo-color-primary);
    -webkit-animation: animateOutline 1.4s infinite;
    animation: animateOutline 1.4s infinite;
  }

  /* Añadido para que en ejercicios de línea de números no se pierda el inicio del primero o el final del último.
   Si lo mejoran el lemonade deberíamos quitarlo
   */
  .lemonade-exercises .lemo-question.lemo-math-number-line {
    padding: 0 2em;
  }

  // CUSTOM CLASSES FOR EDITORS

  .fr-fractional-percent-10 .fractional-shape {
    height: auto;

    svg {
      width: 10%;
      height: auto;
    }
  }

  .fr-fractional-percent-20 .fractional-shape {
    height: auto;

    svg {
      width: 20%;
      height: auto;
    }
  }

  .fr-fractional-percent-30 .fractional-shape {
    height: auto;

    svg {
      width: 30%;
      height: auto;
    }
  }

  .fr-fractional-percent-40 .fractional-shape {
    height: auto;

    svg {
      width: 40%;
      height: auto;
    }
  }

  .fr-fractional-percent-50 .fractional-shape {
    height: auto;

    svg {
      width: 50%;
      height: auto;
    }
  }

  .fr-fractional-percent-60 .fractional-shape {
    height: auto;

    svg {
      width: 60%;
      height: auto;
    }
  }

  .fr-fractional-percent-70 .fractional-shape {
    height: auto;

    svg {
      width: 70%;
      height: auto;
    }
  }

  .fr-fractional-percent-80 .fractional-shape {
    height: auto;

    svg {
      width: 80%;
      height: auto;
    }
  }

  .fr-fractional-percent-90 .fractional-shape {
    height: auto;

    svg {
      width: 90%;
      height: auto;
    }
  }

  .fr-fractional-percent-100 .fractional-shape {
    height: auto;

    svg {
      width: 100%;
      height: auto;
    }
  }

  .fr-fractional-percent-25 .fractional-shape {
    height: auto;

    svg {
      width: 25%;
      height: auto;
    }
  }

  .fr-fractional-percent-75 .fractional-shape {
    height: auto;

    svg {
      width: 75%;
      height: auto;
    }
  }

  .center-div-wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .center-div-nowrap {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
  }

  // CUSTOM LAYOUTS FOR EDITORS
  .lemonade-exercises {
    .numeric-series {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: row;
    }

    .numeric-series__row {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: row;
    }

    .numeric-series__number {
      font-weight: bold;
    }

    .numeric-series__row .numeric-series__number:last-child {
      display: none;
    }

    .numeric-series__row:last-child .numeric-series__number:last-child {
      display: block;
    }

    .numeric-series__ball {
      border-radius: 8px;
      background-color: #e7effe;
      padding: 8px;
      margin: 3px 26px;
      position: relative;

      &:before {
        content: '→';
        position: absolute;
        left: -20px;
      }

      &:after {
        content: '→';
        position: absolute;
        right: -20px;
      }
    }

    @media screen and (max-width: 600px) {
      .numeric-series {
        flex-direction: column;
      }

      .numeric-series__row {
        margin: 0;
      }

      .numeric-series__row .numeric-series__number:last-child {
        display: block;
      }

      .numeric-series__ball {
        &:before {
          content: '→';
          position: absolute;
          left: -20px;
        }

        &:after {
          content: '→';
          position: absolute;
          right: -20px;
        }
      }
    }
  }
}
