.activity-header {
  display: flex;
  align-items: center;
  padding: 16px;
  column-gap: 26px;
  width: 100%;

  .activity-header__experience {
    display: flex;
    flex: 1;
    align-items: center;

    .activity-header__bar-container {
      width: 100%;
      height: 12px;
      border-radius: 14px;
      background: #b8c3f0;

      .activity-header__bar-content {
        width: 0;
        height: 12px;
        border-radius: 14px;
        background: linear-gradient(135deg, #72c4ff 14.75%, #4325fa 100%);
        transition: width 1s;
      }
    }
  }

  .activity-header__issue-report {
    z-index: 14;

    .practice-button {
      padding: 12px !important;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 36px 36px 16px 36px;

    .activity-header__experience {
      position: absolute;
      left: 15%;
      right: calc(15% + 12px);
    }

    .activity-header__issue-report {
      position: absolute;
      right: 36px;
    }
  }

  @media screen and (min-width: 1024px) {
    padding: 36px 36px 16px 36px;

    .activity-header__experience {
      position: absolute;
      left: 25%;
      right: calc(25% + 12px);
    }
  }
}
