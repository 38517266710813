.meta-ranking-item {
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 21px;
  margin: 8px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .meta-ranking-item__icon {
    width: 24px;
    display: flex;
    align-items: center;
    padding-left: 4px;
  }

  .meta-ranking-item__position {
    font-family: 'Quicksand';
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    width: 30px;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }

  .meta-ranking-item__name {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    flex-grow: 1;
    width: 142px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .meta-ranking-item__experience {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 18px;
  }
}

.meta-ranking-item--selected {
  background: #6B8B99;
  border-radius: 2px;
}
