.onboarding-panel__backdrop {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
}

.onboarding-panel {
  width: 649px;
  padding: 24px 106px;
  border-radius: 0 8px 0 8px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--ui-bar-background);
  min-height: 175px;

  z-index: 101;
  position: absolute;
  bottom: 42px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid #FFAF22;
  border-right: 8px solid #FFAF22;

  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1024px) and (orientation: landscape) {
    width: 560px;
  }

  @media (orientation: portrait) {
    bottom: 220px;
  }

  &.centered {
    padding: 18px 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: fit-content;

    @media (orientation: portrait) {
      bottom: unset;
    }
  }

  &.onboarding-panel--intro {
    min-height: auto;
  }

  .onboarding-panel__top {
    width: 649px;
    height: 33px;
    background-image: url("../assets/popup_top_left_small.png"), url("../assets/popup_top_right.png");
    background-position: left top, right bottom;
    background-repeat: no-repeat;
    position: absolute;
    top: -17px;
    left: -8px;

    @media (max-width: 1024px) and (orientation: landscape) {
      width: 560px;
    }
  }

  .onboarding-panel__bottom {
    width: 649px;
    height: 33px;
    background-image: url("../assets/popup_bottom_left.png"), url("../assets/popup_bottom_right_small.png");
    background-position: left top, right bottom;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -17px;
    left: -8px;

    @media (max-width: 1024px) and (orientation: landscape) {
      width: 560px;
    }
  }

  .onboarding-panel-large__top {
    width: 649px;
    height: 49px;
    background-image: url("../assets/popup_top_left.png"), url("../assets/popup_top_right.png");
    background-position: left top, right bottom;
    background-repeat: no-repeat;
    position: absolute;
    top: -33px;
    left: -8px;
  }

  .onboarding-panel-large__bottom {
    width: 649px;
    height: 49px;
    background-image: url("../assets/popup_bottom_left.png"), url("../assets/popup_bottom_right.png");
    background-position: left top, right bottom;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -33px;
    left: -8px;
  }

  .onboarding-panel__close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 103;
    padding: 1.2em;
    margin: -1.2em;

    svg path {
      fill: var(--ui-modal-close-color);
    }
  }

  .onboarding-panel__audio {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;

    path {
      fill: var(--ui-modal-close-color);
    }

    &.left {
      left: 20px;
      right: unset
    }
  }

  // deprecate

  .onboarding-panel__title {
    font-family: Quicksand;
    font-size: 18px;
    font-weight: 700;
    color: var(--ui-modal-header-text-color);
    text-align: center;

    strong {
      font-family: Quicksand;
      font-size: 18px;
      font-weight: 700;
      color: var(--ui-modal-strong-color)
    }
  }

  .onboarding-panel__text {
    font-family: Quicksand;
    font-size: 18px;
    font-weight: 700;
    color: var(--ui-modal-header-text-color);
    text-align: center;
    line-height: 22px;

    strong {
      font-family: Quicksand;
      font-size: 18px;
      font-weight: 700;
      color: var(--ui-modal-strong-color)
    }

    p {
      line-height: 22px;
    }

    .onboarding-panel__text-with-icon {
      display: inline-block;
      margin-top: 0;

      span {
        margin: 0 8px;
        vertical-align: middle;

        svg path {
          fill: var(--ui-modal-content-text-color);
        }
      }
    }

    &.large {
      font-size: 24px;
      line-height: 28px;
      margin: 0 56px;

      strong {
        font-size: 24px;
      }

      p {
        line-height: 32px;

        &:first-child {
          margin-top: 0
        }
      }
    }
  }

  .onboarding-panel__radius-image {
    border-radius: 8px;
    margin: 0 auto;
  }

  .onboarding-panel__daily-goal {
    margin: 12px auto;
    height: 44px;
    display: flex;
    align-items: center;
  }

  .onboarding-panel__image img {
    margin: 12px auto;
  }

  .onboarding-panel__image--no-margin img {
    margin: 0 auto;
  }

  .onboarding-panel__level-image {
    position: relative;

    .onboarding-panel__level-image-text {
      font-family: Quicksand;
      font-size: 28px;
      color: white;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: 2px;
    }
  }



  .onboarding-panel__content {

    // deprecate
    p {
      font-family: Montserrat;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      text-align: left;
      color: var(--ui-modal-content-text-color);
    }

    .onboarding-panel__text-with-icon {
      display: inline-block;
      margin-top: 0;

      span {
        margin: 0 6px;
        vertical-align: middle;

        svg path {
          fill: var(--ui-modal-content-text-color);
        }
      }
    }
  }

  .onboarding-panel__button {
    min-width: 120px;
    width: max-content;
    height: 26px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 36px auto 0;

    padding: 4px 32px;
    border-radius: 6px;
    background-color: #ffaf22;

    font-family: Quicksand;
    font-size: 14px;
    font-weight: bold;
    color: #1c292b;
    cursor: pointer
  }

  .onboarding-panel__button--inline {
    width: 100%;
  }

  .onboarding-panel__button--no-margin {
    margin: 8px auto 0;
  }

  .onboarding-panel__bb-image {
    position: absolute;
    bottom: -26px;
    left: -98px;
    z-index: 1;
  }
}

// Botón invisible para reset de onboarding
.onboarding__debug {
  position: fixed;
  top: 0;
  left: 10px;
  z-index: 1;
  height: 45px;
  width: 45px;
  cursor: pointer;
}


// Onboarding Introducción
.onboarding-intro {
  height: 100%;
  width: 100%;
  background-color: black;
  z-index: 102;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .onboarding-intro__background {
    position: relative;
    height: 100%;
    width: 100%;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 90%;
      width: 90%;
      object-fit: contain;
    }

    .onboarding-intro__background-img {
      opacity: 0;
      pointer-events: none;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .shake {
    animation: shake 0.6s;
    animation-delay: 1.3s;
  }

  .fade-out {
    animation: fade-out 1s ease-out both;
  }

  .fade-in {
    animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1s both;
  }

  .fade-in-cross {
    animation: fade-in 1s ease-in both;
  }

  .fade-in-start {
    animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }

  &.fade-out-final {
    animation: fade-out 1s ease-out 1s both;
  }

  .onboarding-intro__avatar-selector {
    position: absolute;

    // background: #FFAF22;
    // box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 24px;

    height: 70%;
    width: 90vw;
    max-width: 1030px;

    z-index: 101;
    padding: 0;

    /* >div {
      background-color: #fff;
      border-radius: 16px;
      height: 100%;
      background-image: url(../../../../assets/img/metaberry_ui/computer_bg.png);
      background-position: center;
      background-size: cover;
    } */
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg) scale(1);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg) scale(1.5);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg) scale(1.5);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg) scale(1.5);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg) scale(1.5);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg) scale(1.5);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg) scale(1.5);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg) scale(1.5);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg) scale(1.5);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg) scale(1.5);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg) scale(1);
  }
}
