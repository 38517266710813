@media (max-height: 750px) {
  .meta-options-menu-drawer__tab-content {
    height: calc(100% - 134px);
  }
}

.metatabavatar-container-total{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
  padding: 20px;
  width: 100%;

  .metatabavatar-container-total__item {
    border-radius: 5px;
    padding: 20px;    
  }

  .map-tabs-tooltip__tooltip{
    left: 1px;
    bottom: -35px;

    @media ((min-width: 832px) and (max-width: 1018px)) {
      left: 15px;
    }

    @media (max-width: 830px) {
      left: -6px;
    }

    @media (max-width: 774px) {
      left: 40px;
    }

    @media (max-width: 715px) {
      left: 20px;
    }

    @media (max-width: 620px) {
      left: 0px;
    }
  }
  
}
