.streak-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.streak-progress__bullet {
  margin-top: 8px;
  z-index: 2;
}

.streak-progress__connector {
  width: 100px;
  height: 13px;
  margin: 0 -14px;
  z-index: 1;
}

.streak-progress__connector--on {
  // border-radius: 0px 50px 50px 0px;
  background: #06BF85;
  box-shadow: 0px 2px 0px 0px #00E19A inset;
}

.streak-progress__connector--off {
  background: #1B1B1B;
}
