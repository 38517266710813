@import '../../../assets/styles/blueberry.scss';

.activity-blueberry-component {
  .activity-button {
    @include activity-primary-font;
    font-weight: 700;
    display: flex;
    flex: 1;
    max-height: 48px;
    align-items: center;
    justify-content: center;
    border-radius: 36px;
    padding: 12px 32px;
    width: fit-content;
    min-width: 240px; // fit-content;
    height: 48px;
    line-height: 48px;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    gap: 8px;

    @keyframes activity-button-loading-animation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    .activity-button_loading {
      height: 1em;
      width: 1em;
      padding: 0;
      margin: 0;
      animation: activity-button-loading-animation 1.5s linear infinite;
    }

    // Primario
    color: map-get($activity-colors, white-bb);
    background-color: map-get($activity-colors, primary-medium);
    path {
      fill: map-get($activity-colors, white-bb);
    }
    &:active {
      background-color: map-get($activity-colors, primary-dark);
    }
    &.active {
      background-color: map-get($activity-colors, primary-dark);
    }

    // Secundario
    &.type-2 {
      color: map-get($activity-colors, primary-medium);
      background-color: map-get($activity-colors, white-bb);
      border: solid map-get($activity-colors, primary-medium) 2px;

      path {
        fill: map-get($activity-colors, primary-medium);
      }

      &:active {
        background-color: map-get($activity-colors, primary-light-1);
      }
      &.active {
        background-color: map-get($activity-colors, primary-light-1);
      }
    }

    // Terciario
    &.type-3 {
      color: map-get($activity-colors, primary-medium);
      background-color: map-get($activity-colors, primary-light-2);
      border: none;

      path {
        fill: map-get($activity-colors, primary-medium);
      }

      &:active {
        background-color: map-get($activity-colors, primary-light-1);
      }
      &.active {
        background-color: map-get($activity-colors, primary-light-1);
      }
    }

    // Outline
    &.type-4 {
      color: map-get($activity-colors, white-bb);
      background-color: transparent;
      border: solid map-get($activity-colors, white-bb) 2px;

      path {
        fill: map-get($activity-colors, white-bb);
      }

      &:active {
        color: map-get($activity-colors, primary-dark);
        border: solid map-get($activity-colors, primary-dark) 2px;

        path {
          fill: map-get($activity-colors, primary-dark);
        }
      }

      &.active {
        color: map-get($activity-colors, primary-dark);
        border: solid map-get($activity-colors, primary-dark) 2px;

        path {
          fill: map-get($activity-colors, primary-dark);
        }
      }
    }

    // Deshabilitado
    &.disabled {
      background-color: map-get($activity-colors, disabled-back);
      color: map-get($activity-colors, disabled-front);

      path {
        fill: map-get($activity-colors, disabled-front);
      }
    }

    &.icon-only {
      width: fit-content !important;
      max-width: fit-content !important;
      min-width: fit-content !important;
      padding: 0 21px !important;
    }

    &.swap-icon-text {
      flex-direction: row-reverse;
    }

    &.fit-content {
      min-width: fit-content;
    }

    @media screen and (min-width: 768px) {
      flex: initial;
    }
  }
}
