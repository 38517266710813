.avatar-icon {
  position: relative;
  width: 56px;
  height: 56px;

  .avatar-icon__bg {
    position: relative;
    top: 20px;
    left: 1px;
  }

  .avatar-icon__image {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
    background-repeat: no-repeat;
    background-size: 125%;
    background-position: top;
  }

  .avatar-icon__image--regular {
    background-position: top;
  }

  .avatar-icon__image--small {
    background-position: -73px -80px;
  }

  .avatar-icon__image--large {
    background-size: 110%;
    background-position: -64px -71px;
  }
}
