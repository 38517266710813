.confirm-popup__backdrop {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.50);
  // backdrop-filter: blur(20px);
  z-index: 1010;
  display: flex;
  justify-content: center;
  align-items: center;  
}

.confirm-popup {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px;
  border-radius: 16px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.25);
  background-color: #FFF;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 1020;

  .confirm-popup__content {
    display: flex;
    width: 100%;
  }

  .confirm-popup__left {
    width: 138px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 24px;
  }

  .confirm-popup__right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

  }

  .confirm-popup__actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;

    div:last-child {
      margin-left: 24px;
    }
  }
}

.confirm-popup--size-sm {
  max-width: 460px;

  @media only screen and (max-width:600px) {
    padding: 24px !important;
    max-width: 100vw !important;
    height: auto !important;
    width: 100vw;
  }

  @media only screen and (max-width: 460px) {
    width: 100%;
    border-radius: 0;
  }
}

.confirm-popup--size-default,
.confirm-popup--size-md {
  max-width: 610px;
}

.confirm-popup--size-lg {
  max-width: 760px;
}


.confirm-popup__button {
  display: flex;
  height: 36px;
  padding: 8px 16px;
  align-items: center;
  color: #1C292B;
  text-align: center;
  font-family: Quicksand;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.confirm-popup__button--default {
  border-radius: 12px;
  border: 2px solid #576B70;
  background: #FFF;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.10);
}

.confirm-popup__button--available {
  border-radius: 40px;
  border: none;
  background: #FFAF22;
  box-shadow: none;
}

.confirm-popup__button--disabled {
  border-radius: 40px;
  border: none;
  background: #F2F2F2;
  box-shadow: none;
  pointer-events: none;
}