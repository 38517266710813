#__blueberry-modal-container-teacher {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1002;
}

.modal__hidden {
  opacity: 0;
}

.modal-teacher {
  .modal__shadow-layer,
  .modal__content-wrapper-init {
    position: absolute;
    width: 100%;
    height: 100vh;
    /*display: none;*/
  }

  .modal__content-wrapper-icon{
    position: absolute;
    top: -64px;
    left: 115px;
    z-index: 10;
  }

  .modal__content-wrapper-icon-error{
    position: absolute;
    top: -109px;
    left: 106px;
    z-index: 10;
  }

  .modal__shadow-layer {
    background: black;
  }

  .modal__content-wrapper-init {
    display: flex;
    align-items: center;
    justify-content: center;    

    .modal__content-wrapper-init__container-text{
      max-width: 325px;
      line-height: 1.5;
      .modal__content-wrapper-init__title{
        @include poppins-font($size: 20px, $weight: 700);
        text-align: center;
        color: $gray-1-teacher-practice;
        word-wrap: break-word;
        margin-bottom: 5px;
      }
  
      .modal__content-wrapper-init__subtitle{
        @include poppins-font($size: 16px, $weight: 400);
        text-align: center;
        color: $gray-1-teacher-practice;
        word-wrap: break-word;
      }      
    }

    .modal__content-wrapper-init__button{
      @include poppins-font($size: 16px, $weight: 400);
      color: $white;
      width: 100%;
      height: 48px;
      padding-left: 32px;
      padding-right: 32px;
      padding-top: 12px;
      padding-bottom: 12px;
      background: #5A34E1;
      border-radius: 36px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      display: flex;
      line-height: 24px;
      word-wrap: break-word;
      box-shadow: 0 4px 0 #4426af;
      margin-top: 16px;

      &:hover {
        cursor: pointer;
      }
    
      &:active,
      &:target {
        box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.25) !important;
        position: relative;
        top: 4px;
      }
    }
  }

  .modal__content {
    position: relative;
    max-width: 500px;
    padding: 20px;
    background: $white;
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.10);
    border-radius: 24px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 6;
    display: 'inline-flex';
    padding-top: 65px;
  }

  .modal__close-button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    right: -10px;
    top: -10px;
    border-radius: 100%;
    background-color: red;
    color: $white;
    z-index: calc(#{$modal-depth} + 1);
    cursor: pointer;
  }

  .iconClose {
    width: 25px;
    height: 25px;
    path {
      fill: white;
    }
  }
}
.modal-screen-portrait-practice{
  display: none;
  .modal__content-wrapper-icon-error {
    top: -93px;
  }
  .modal__content {
    padding-top: 40px;
  }
}
@media screen and (orientation:portrait) {
  .modal-screen-portrait-practice{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: rgba(0,0,0,0.6);
    color: white;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 2em;
    transform-origin: center center;
    z-index: 9999;
  }
}
