@use '../../../../theme/index'as theme;

.error-message {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #FF3D30;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  padding: 8px 24px;

  opacity: 0;
  transform: translateY(-40px);
  transition: all 200ms;

  z-index: 1
}

.error-message.visible {
  opacity: 1;
  transform: translateY(0);
}

.error-message__error-icon path {
  fill: #fff;
}

.error-message__text {
  color: #FFF;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin: 0 12px;
}
