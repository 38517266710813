.streak-card {
  height: 136px;
  width: 96px;
  padding: 0 0 8px 0px;
  border-radius: 12px;
  background: #384346;
  margin: 7px;
  position: relative;
}

.streak-card__ribbon-wrapper {
  width: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: -8px;
}

.streak-card__number {
  color: #FFF;
  text-align: center;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
  font-family: Quicksand, sans-serif;
  font-size: 18px;
  font-weight: 700;

  position: absolute;
  top: 2px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.streak-card__check {
  width: 24px;
  height: 24px;
  padding: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 4px;
  top: -8px;

  border-radius: 60px;
  border: 1px solid #88A7B8;
  background: #384346;
}

.streak-card__top-text {
  padding: 4px 8px;
  border-radius: 40px;
  border: 1px solid #88A7B8;
  background: #384346;

  color: #C3D3DB;
  text-align: right;
  font-family: Quicksand, sans-serif;
  font-size: 14px;
  font-weight: 700;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: -12px;
  left: 50%;
  transform: translate(-50%);
}

// variants
.streak-card--today {
  border: 2px solid #FFF;
  background: #00E19A;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.20);
  height: 144px;
}
