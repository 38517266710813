.recover-password-page {
  height: 100vh;
  height: -webkit-fill-available;
  background-color: royalblue;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.recover-password-page__left-box {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 440px);
  height: 100%;
}

.recover-password-page__cover-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.recover-password-page__right-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  width: 460px;
  height: 100%;
  background-color: white;
  border-radius: 20px 0 0 20px;
  padding: 0 42px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
}

.recover-password-page__bb-logo {
  margin-top: 50px;
}

.recover-password-page__google-button {
  display: flex;
  align-self: center;
  justify-content: center;
  padding: 1em 0.5em 0 0.5em;
  width: 100%;
  margin-top: 12px;
}



.recover-password-page__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  width: 100%;
  height: 40px;

  .form-button {
    padding-left: 0;
    flex-grow: 1;
  }

  .recover-password-page__oc-logo {
    display: flex;
    justify-content: flex-end;
    width: 80px;
    flex-grow: 1;
  }

}
