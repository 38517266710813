.confirm-popup-options__backdrop {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.50);
  // backdrop-filter: blur(20px);
  z-index: 1010;
  display: flex;
  justify-content: center;
  align-items: center;  
}

.confirm-popup-options {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 24px;
  border-radius: 42px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.25);
  background-color: #FFF;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 1020;

  .confirm-popup-options__content {
    display: flex;
    width: 100%;
  }

  .confirm-popup-options__left {
    width: 138px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 24px;
  }

  .confirm-popup-options__right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

  }

  .confirm-popup-options__actions {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;

    div:last-child {
      margin-left: 24px;
    }
  }

  .confirm-popup-options__actions.confirm-popup-options__button--only {
    div:last-child {
      margin-left: 0;
    }
  }
}

.confirm-popup-options--size-sm {
  max-width: 460px;

  @media only screen and (max-width:600px) {
    padding: 24px;
    max-width: 100vw;
    height: auto;
    width: 100vw;
  }

  @media only screen and (max-width: 460px) {
    width: 100%;
    border-radius: 0;
  }
}

.confirm-popup-options--size-xs {
  max-width: 300px;

  @media only screen and (max-width:600px) {
    padding: 24px;
    max-width: 100vw;
    height: auto;
    width: 100vw;
  }

  @media only screen and (max-width: 460px) {
    width: 100%;
    border-radius: 0;
  }
}

.confirm-popup-options--size-default,
.confirm-popup-options--size-md {
  max-width: 610px;
}

.confirm-popup-options--size-lg {
  max-width: 760px;
}


.confirm-popup-options__button {
  display: flex;
  height: 36px;
  padding: 8px 16px;
  align-items: center;
  color: #1C292B;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
}

.confirm-popup-options__button--default {
  border-radius: 12px;
  border: 2px solid #576B70;
  background: #FFF;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.10);
}

.confirm-popup-options__button--available {
  border-radius: 40px;
  border: none;
  background: #5A34E1;
  box-shadow: none;
  color: #FFFFFF;
  padding: 12px 32px;
}

.confirm-popup-options__button--disabled {
  border-radius: 40px;
  border: none;
  background: #F2F2F2;
  box-shadow: none;
  pointer-events: none;
  color: #AFB7C6;
}

.confirm-popup-options-close-button {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: #5A34E1;
  border-radius: 50%;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 17px;
    height: 17px;
    fill: #FFFFFF;
  }
}

.card-preview {
  .confirm-popup-options-close-button {
    svg {
      width: 12px;
      height: 12px;
    }
  }

  .confirm-popup-options__button{
    padding: 12px 32px 12px 32px;
    height: 48px;
  }
}