.mission-daily-reward-card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 297px;
  height: fit-content;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.2);

  &:hover {
    box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.5);
  }

  .mission-daily-reward-card_image {
    width: 120px;
    position: absolute;
    top: -48px;

    img {
      width: 100%;
    }
  }

  .mission-daily-reward-card_information {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 24px 24px 24px;

    .mission-daily-reward-card_title {
      color: #000;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 24px;
      font-weight: 700;
      padding-top: 90px;
    }

    .mission-daily-reward-card_message {
      color: #576b70;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      padding: 12px 14px 0 14px;
    }
  }

  .mission-daily-reward-card_actions {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 297px;
    height: 59px;
    border-radius: 0px 0px 16px 16px;
    background: #ffaf22;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.2);

    .mission-daily-reward-card_get-button {
      display: inline-flex;
      height: 36px;
      padding: 8px 16px;
      align-items: center;
      gap: 4px;
      border-radius: 12px;
      border: 2px solid #576b70;
      background: #fff;
      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.1);
      cursor: pointer;

      color: #1c292b;
      text-align: center;
      font-family: Quicksand, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      &:active {
        background: #e1e1e1;
        height: 34px;
        font-size: 17px;
      }
    }
  }

  .missions_daily_reward_timer {
    width: 100%;
    display: flex;
    padding: 0 30px;
    justify-content: center;
    align-items: center;
    position: relative;

    .missions_daily_reward_timer-chronometer {
      flex: 0 0 auto;
      width: 40px;
      height: auto;
      position: relative;
      right: -5px;
      z-index: 2;
    }

    .missions_daily_reward_timer-bar {
      flex-grow: 1;
      height: 13px;
      background-color: #cccccc;

      .missions_daily_reward_timer-bar-inner {
        width: 100%;
        border-radius: 0px 50px 50px 0px;
        background: #06bf85;
        box-shadow: 0px 2px 0px 0px #00e19a inset;
        height: 13px;
      }
    }

    .missions_daily_reward_timer-coin {
      flex: 0 0 auto;
      width: 40px;
      height: auto;
      position: relative;
      left: -5px;
      z-index: 2;
    }
  }

  .missions_daily_reward_timer-message {
    color: #576b70;
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0 24px 24px 24px;
  }
}
