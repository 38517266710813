.map-bar-tooltip__backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  // background: rgba(28, 41, 43, 0.5);
  z-index: 200;
}

.map-bar-tooltip {
  position: relative;
  height: 100%;
  z-index: 101;

  .map-bar-tooltip__element {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
  }

  .map-bar-tooltip__tooltip {
    border-radius: 12px;
    background: #5A34E1;
    box-shadow: -8px -5px 12px 0px rgba(0, 0, 0, 0.17) inset;
    display: none;

    flex-direction: column;
    min-width: 160px;
    padding: 16px 14px;

    position: absolute;
    transform-origin: bottom left;
    transform: translate(-30px, 22px);

    &:before {
      content: '';
      width: 15px;
      height: 15px;
      border-radius: 3px;
      background: #5A34E1;
      transform: rotate(45deg);
      position: absolute;
      top: -7px;
    }
  }

  &:hover .map-bar-tooltip__tooltip,
  .map-bar-tooltip__tooltip.map-bar-tooltip__tooltip--visible {
    display: flex;
  }

  .map-bar-tooltip__tooltip--center:before {
    left: calc(50% - 7px);
  }

  .map-bar-tooltip__tooltip--left:before {
    right: initial;
    left: 40px;
  }

  .map-bar-tooltip__tooltip--right:before {
    right: 40px;
    left: initial;
  }

  .map-bar-tooltip__tooltip--left {
    transform: translateY(30px);
  }

  .map-bar-tooltip__tooltip--right {
    transform-origin: bottom left;
    transform: translate(-170px, 30px);
  }

  .map-bar-tooltip__message {
    padding: 8px 12px;

    border-radius: 8px;
    background: #3703A4;
    color: #FFF;
    text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.20);
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
  }

  .map-bar-tooltip__content {
    margin-top: 12px;
  }
}
