.meta-lesson-panel {
  width: 300px;
  height: auto;
  background: var(--ui-bar-background);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);

  border-radius: 8px;
  position: fixed;
  z-index: 2;
  padding: 12px 14px 16px 12px;

  flex-direction: column;

  .meta-lesson-panel__blocked {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .meta-lesson-panel__blocked-icon {
    svg path {
      fill: var(--ui-primary-color);
    }
  }

  .meta-lesson-panel__blocked-title {
    font-family: 'Quicksand';
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: var(--ui-primary-color);
    text-align: center;
    margin: 14px 0 12px 0;
  }

  .meta-lesson-panel__blocked-info {
    font-family: 'Quicksand';
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--ui-primary-color);
    text-align: center;

    .meta-lesson-panel__previous-name {
      display: block;
      font-size: 115%;
      padding-top: 0.5em;
    }
  }

  .meta-lesson-panel__previous-image {
    padding: 1em;

    img {
      width: 100%;
      border-radius: 8px;
      border: 2px solid var(--ui-primary-color);
    }
  }

  .meta-lesson-panel__previous-image {
    padding: 1em;

    img {
      width: 100%;
      border-radius: 8px;
      border: 1px solid var(--ui-primary-color);
    }
  }

  .meta-lesson-panel__lesson-number {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: var(--ui-primary-color);
    margin: 16px 0;
    text-align: center;
  }

  .meta-lesson-panel__lesson-title {
    font-family: Quicksand;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--ui-primary-color);
    margin: 16px 0;
    text-align: center;
  }

  .meta-lesson-panel__lesson-pieces {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px 12px 30px 12px;
    min-width: 200px;

    .meta-lesson-panel__lesson-pieces_gear {
      z-index: 2;

      svg {
        width: 44px;
        height: 44px;
      }
    }

    .meta-lesson-panel__lesson-pieces_bar {
      position: relative; /* Para moverlo tras el iconoy el número de piezas */
      left: -24px; /* Para moverlo tras el iconoy el número de piezas */
      margin-right: -34px; /* Para moverlo tras el iconoy el número de piezas */

      flex-grow: 1;
      height: 29px;
      border-radius: 22px;
      background: #3703a4;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;

      .meta-lesson-panel__lesson-pieces_bar_foregrond {
        height: 100%;
        border-radius: 18px;
        background: linear-gradient(180deg, #7c3de5 0%, #3c3cbf 100%);
        box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.15) inset,
          0px 5px 4px 0px rgba(255, 255, 255, 0.25) inset;
      }
    }

    .meta-lesson-panel__lesson-pieces_number {
      display: inline-flex;
      padding: 16px 8px 17px 8px;
      justify-content: center;
      align-items: center;
      border-radius: 40px;
      background: #fff;
      color: #000;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 0px;
      z-index: 2;

      div:first-of-type {
        font-size: 14px;
        font-weight: 700;
        padding-right: 3px;
      }
      div:last-of-type {
        padding-right: 1px;
      }
    }
  }

  .meta-lesson-panel__button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffaf22;
    border-radius: 18px;
    padding: 8px 12px;
    color: #1c292b;
    font-family: 'Quicksand';
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
    width: fit-content;
    margin: 0 auto;
  }
}
