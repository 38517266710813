.login-page {
  height: 100vh;
  height: -webkit-fill-available;
  background-color: royalblue;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.login-page__left-box {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 370px);
  height: 100%;
}

.login-page__cover-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.login-page__right-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  width: 390px;
  height: 100%;
  background-color: white;
  border-radius: 20px 0 0 20px;
  padding: 0 42px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  overflow: auto;

  @media (max-width: 460px) {
    width: 100%;
    padding: 0 24px;
    border-radius: 0;
  }
}

.login-page__bb-logo {
  margin-top: 50px;

  svg {
    width: 100%
  }
}

.login-title {
  font-family: 'Poppins', sans-serif;
  color: #1B1A26;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 32px;
  text-align: center;
}

.login-page__google-button {
  display: flex;
  align-self: center;
  justify-content: center;
  padding: 1em 0.5em 0 0.5em;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 16px;
}

.login-page__signup-button {
  margin-top: 8px
}

.login-page__oc-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.login-page__separator {
  width: 100%;
  margin: 8px 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  height: 22px;

  .login-page__separator-line {
    display: inline-block;
    flex-grow: 1;
    width: 100%;
    height: 2px;
    background: rgba(195, 194, 207, 0.6);
    border-radius: 10px;
  }

  .login-page__separator-text {
    display: inline-block;
    margin: 0 12px;
    flex-grow: 0;
    color: #88A7B8;
    text-align: center;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
}
