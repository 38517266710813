@import '../../../assets/styles/blueberry.scss';

.activity-blueberry-component {
  .feedback-indicator {
    position: relative;
    padding: 14px 28px 14px 66px;
    border-radius: 4px 42px 42px 42px;
    background: map-get($activity-colors, white-bb);
    box-shadow: 0px 1px 5px 0px map-get($activity-colors, shadow-01);
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-weight: 700;

    &.feedback-indicator--correct {
      color: map-get($activity-colors, green-bb);
    }

    &.feedback-indicator--incorrect {
      color: map-get($activity-colors, red-bb);
    }

    svg {
      position: absolute;
      height: auto;
      width: 60px;
      left: 0;
      bottom: 0;
      transform: translate(0%, 9%);
    }
  }
}
