.avatar-data-container{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    flex-direction: column;

    .avatar-data-container__title{
        font-family: 'Quicksand', sans-serif;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
    }

    .avatar-data-container__description {
        background-image: url('../../../../../assets/img/box-avatar-description.svg');
        background-position: center;
        background-size: auto;
        background-repeat: no-repeat;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;

        .avatar-data-container__description-text{
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            width: 60%;
            text-align: center;
        }
    }
    
}