.practice-view {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  &.practice-view_iPhone {
    padding-bottom: 30px;
  }

  .practice-view__exercise {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &.practice-view_desktop-size {
    .dummy-activity,
    .activity-view_content__with-audio-button,
    .feedback-box {
      margin: 0 25% 3% 25%;
    }

    .activity-view__feedback-button-wrapper,
    .activity-footer {
      margin: 0 25%;
    }
  }

  &.practice-view_tablet-size {
    .dummy-activity,
    .activity-view_content__with-audio-button,
    .feedback-box {
      margin: 0 15% 3% 15%;
    }

    .activity-view__feedback-button-wrapper,
    .activity-footer {
      margin: 0 15%;
    }
  }

  &.practice-view_phone-size {
    .dummy-activity,
    .activity-view_content__with-audio-button,
    .feedback-box {
      margin: 0 16px 20px 16px;
    }

    .activity-view__feedback-button-wrapper,
    .activity-footer {
      margin: 0 16px;
    }
  }
}

/*
.lemo-tooltip {
  visibility: hidden;

  .lemo-tooltip__arrow,
  .lemo-tooltip__wrapper > div > div.lemo-tooltip-content__close > button {
    display: none;
  }
}
*/
