.quiz-loading {
  // Altura de la caja de resultados: padding + fuente + padding + padding + fuente + padding + padding
  $results-height: calc(24px + 14px + 10px + 36px + 30px + 36px + 24px);

  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  // background: linear-gradient(180deg, #3a1587 9%, #490dc8 99.99%, #3703a4 100%);
  // background: url('../../assets/backgorund_podium.svg');
  z-index: 215;
  display: flex;
  flex-direction: column;
  align-items: center;

  .quiz-infinite-space {
    background-image: url('../../assets/backgorund_podium.svg');
    background-repeat: repeat-x;
    background-position: left center;
    background-size: auto 100%;
    animation: scrollSpace 3s linear infinite;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .quiz-loading_text {
    color: #fff;
    font-family: Poppins, sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 68px;
    margin-bottom: 97px;
    margin-top: -100px;
  }
  /* La animación que hará que el fondo se desplace de derecha a izquierda */
  @keyframes scrollSpace {
    from {
      background-position: 0 center;
    }
    to {
      background-position: calc(100% - 100vw) center;
    }
  }

  .quiz-loading__wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;

    .quiz-loading__title {
      height: 30%;
      color: #fff;
      font-family: Inter;
      font-size: 45px;
      font-weight: 700;
      padding: 0 48px 8px 48px;
      display: flex;
      align-items: center;
      text-align: center;
    }

    .quiz-loading__title-game-started {
      font-size: 32px;
    }

    .quiz-loading__ship_all {
      height: 70%;
      position: relative;
      display: flex;
      gap: 10px;
      align-items: center;

      animation: shipAll 3s linear infinite;

      .quiz-loading__ship {
        height: 30%;
        // transform: scale(-1, 1);
      }

      .quiz-loading__smoke {
        height: 8%;
        position: relative;
        left: 0;

        margin-top: 15%;
        transform: scale(0.7) translateX(0);
        animation: smoke 0.8s linear infinite alternate;
      }
    }
  }

  @keyframes shipAll {
    0% {
      transform: rotate(0deg) translateX(40%) rotate(0deg);
    }
    100% {
      transform: rotate(360deg) translateX(40%) rotate(-360deg);
    }
  }

  @keyframes smoke {
    0% {
      margin-top: 15%;
      transform: scale(0.7) translateX(0);
    }
    25% {
      margin-top: 15%;
      transform: scale(0.7) translateX(0);
    }
    50% {
      margin-top: 10%;
      transform: scale(1.3) translateX(-19%);
    }
    100% {
      margin-top: 10%;
      transform: scale(1.3) translateX(-19%);
    }
  }
}
@media screen and (max-width: 1024px) and (max-height: 768px) {
  .quiz-loading {
    .quiz-loading_text {
      margin-bottom: 130px;
      margin-top: -116px;
    }
  }
}

@media screen and (max-width: 768px) and (max-height: 1024px) {
  .quiz-loading {
    .quiz-loading_text {
      margin-bottom: 206px;
    }
  }
}

@media screen and (max-width: 1024px) and (max-height: 600px) {
  .quiz-loading__ship {
    margin-bottom: 50px;
  }
  .quiz-loading {
    .quiz-loading_text {
      margin-bottom: 111px;
    }
  }
}
