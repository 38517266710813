.earned-piece {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(28, 28, 28, 0.4);
  z-index: 13;

  .earned-piece_frame {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    aspect-ratio: 1/0.6;
    text-align: center;

    .earned-piece_content {
      position: absolute;
      bottom: 1%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;      
      gap: 8px;
      width: 70%;
      height: fit-content;

      .earned-piece_gear {
        svg {
          height: 146px;
          width: auto;
        }
      }

      .earned-piece_text {
        padding: 0 60px 16px 60px;
        color: #ffffff;
        text-shadow: 3px 1.6px 0px rgba(0, 0, 0, 0.25);
        font-family: Poppins, sans-serif;
        font-size: 42px;
        font-weight: 700;
        line-height: 50px;
      }
    }
  }

  @media screen and (max-width: 700px) {
    .earned-piece_frame {
      width: calc(100% - 16px);
      max-width: 114vw;

      .earned-piece_content {
        .earned-piece_gear {
          svg {
            height: 26vw;
            width: auto;
          }
        }

        .earned-piece_text {
          padding: 1vw 9vw 4vw 9vw;
          font-size: 8vw;
          line-height: 9vw;          
        }
      }
    }
  }
}
