.map-score-bar {
  z-index: 101;
  position: absolute;
  top: 27px;
  left: 44px;

  display: flex;
  height: 56px;
  padding: 6px 30px;
  align-items: center;
  gap: 40px;
  border-radius: 36px;
  background: #5C36E2;
  box-shadow: 0px -10px 15px 0px rgba(0, 0, 0, 0.25) inset;

  width: 100%;
  max-width: 786px;

  .map-score-bar__daily-goal {
    width: 100%;
    max-width: 431px;
  }
}

@media (max-width: 1150px) {
  .map-score-bar {
    left: 34px;
    width: 545px;
    gap: 30px;
    padding: 6px 20px;

    .map-score-bar__daily-goal {
      width: 100%;
      max-width: 431px;

      .map-bar-tooltip__tooltip--center:before {
        right: initial;
        left: 40px;
      }
    }
  }
}


@media (max-width: 900px) {
  .map-score-bar {
    width: 400px;
    gap: 10px;

    .map-score-bar__daily-goal {
      max-width: 190px;
    }
  }
}

@media (max-width: 765px) {
  .map-score-bar {
    left: 24px;
  }
}
