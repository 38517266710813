.map-options-bar {
  z-index: 101;
  position: absolute;
  top: 27px;
  right: 44px;
  height: 53px;
  width: fit-content;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: flex-end;

  .map-options-bar__wrapper {
    display: flex;
    height: 56px;
    padding: 8px 24px;
    align-items: center;
    gap: 12px;
    border-radius: 36px;
    background: #5C36E2;
    box-shadow: 0px -10px 15px 0px rgba(0, 0, 0, 0.25) inset;
  }

  .map-options-bar__avatars-button {
    width: 56px;
    height: 56px;
    cursor: pointer;
    margin-right: 8px;
    position: relative;
  }

  .map-options-bar__search-button {
    cursor: pointer;
  }
  
  .map-options-bar__user-menu-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    padding: 8px 16px;
    color: #ffffff;
    background: #3703A4;
    border-radius: 40px;
    font-family: Poppins, sans serif;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;

    svg path {
      fill: #ffffff;
    }

    span {
      margin-left: 12px;
    }
  }

  .map-options-bar__sound-button {
    cursor: pointer;

    svg path {
      fill: #ffffff;
    }
  }

  .map-options-bar__avatars-tooltip {
    display: none;
    flex-direction: column;
    align-items: center;
    padding: 8px 12px;
    width: max-content;
    border-radius: 8px;
    background: #3703A4;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.10);
    color: #FFF;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    line-height: 16px;
    bottom: 88px;
    text-align: center;

    &:before {
      content: '';
      width: 12px;
      height: 12px;
      background: #3703A4;
      transform: rotate(45deg);
      position: absolute;
      bottom: 26px;
    }
  }

  .map-options-bar__avatars-button {
    &:hover {
      .map-options-bar__avatars-tooltip {
        display: flex;
        left: -113px;
        width: 280px;
        bottom: -44px;
      }
    }
  }

  .map-options-bar__avatars-button-halo {
    height: 56px;
    width: 56px;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 0 0 3px #30FFB1;
    border-radius: 50%;
    animation: notificationHaloAvatar 5000ms infinite linear;
    opacity: 0;
  }

  .map-options-bar__avatars-button-exclamation {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -1%;
    right: -12%;
    width: 20px;
    height: 20px;
    color: #ffffff;
    background-color: #ff0000;
    border-radius: 50%;
    box-shadow: 0px 10px 2px 0px rgba(0, 0, 0, 0.25);
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 14px;
    animation: notificationAvatar 900ms infinite ease-in-out;
    z-index: 9;
  }

  @keyframes notificationHaloAvatar {
    5% {
      opacity: 1;
    }

    10% {
      opacity: 0.5;
    }

    20% {
      opacity: 0;
    }
  }

  @keyframes notificationAvatar {
    15% {
      top: -15%;
    }

    30% {
      top: -1%;
    }

    45% {
      top: -15%;
    }

    60% {
      top: -1%;
    }

    75% {
      top: -1%;
    }
  }

}

@media (max-width: 900px) {
  .map-options-bar {
    right: 24px;
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}
