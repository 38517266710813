.meta-popup-selector {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
    position: relative;
    background-color: rgba(104, 63, 172, 1);
    border-radius: 24px;
    background-image: url('../../../assets/img/ellipse-big.svg');
    background-position: top;
    background-size: 38%;
    background-repeat: no-repeat;
    
  
    .meta-popup-selector__header {
      // background-color: rgba(0, 0, 0, 0.25);
    }
  
    .meta-popup-selector__header-top {
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.25);
      border-radius: 24px 24px 0 0;
  
      .meta-popup-selector__header-left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;
        .meta-popup-selector__header-left__content{
          width: 139px;
          height: 74px;
          border-radius: 24px 37px 37px 0;
          background-color: #9940C3;
          box-shadow: 0px 4px 47px 0px rgba(255, 255, 255, 0.25) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          display: flex;
          justify-content: center;
          align-items: center;

          .meta-popup-selector__header-left__content-level{
            //height: 56px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .meta-popup-selector__header-left__content-level-text {
              color: #FFFFFF;
              background-color: #3703A4;
              padding: 6px 12px;
              border-radius: 18px 0 0 18px;
              margin-left: -20px;
              font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              font-family: Poppins, sans-serif;
              font-weight: 700;
              font-size: 12px;
              line-height: 19px;
            }
            .map-level-counter {
              position: absolute;
              right: -27px;
              .map-level-counter-text{
                top: -6px;
              }
            }
          }

          .meta-popup-selector__header-left__content-coins{
            //height: 56px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .meta-popup-selector__header-left__content-coins-text {
              color: #FFFFFF;
              background-color: #3703A4;
              padding: 6px 18px;
              border-radius: 18px 0 0 18px;
              margin-left: 40px;
              font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 19px;
            }

            .meta-popup-selector__header-left__content-coins-icon {
              position: absolute;
              right: -27px;
              top: -4px;

              svg {
                height: 42px;
                width: 42px;
              }
            }
            
            
          }

        }

        .meta-popup-selector__header-left__content-big{
          width: 239px;
        }
      }
  
      .meta-popup-selector__header-center {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
      }
  
      .meta-popup-selector__header-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;
  
        .meta-popup-selector__header-right__content{
          width: 139px;
          height: 74px;
          border-radius: 37px 24px 0px 37px;
          background-color: #9940C3;
          box-shadow: 0px 4px 47px 0px rgba(255, 255, 255, 0.25) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }

        .meta-popup-selector__header-right__content-empty{
          width: 139px;
          height: 74px;          
        }
  
        .shop-level {
          margin-right: 9px;
        }
  
        .meta-popup-selector__header-tooltip {
          position: absolute;
          top: 44px;
          left: -40px;
          width: 250px;
          padding: 8px 12px;
          background: #232f40;
          border-radius: 4px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          font-family: Montserrat, sans-serif;
          font-weight: 600;
          font-size: 12px;
          color: #fff;
          line-height: 18px;
          z-index: 99;
  
          &.fade-in {
            animation: fadeInAnimation ease 0.6s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
          }
  
          &.fade-out {
            animation: fadeOutAnimation ease 0.6s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
          }
        }
  
        @keyframes fadeInAnimation {
          0% {
            opacity: 0;
          }
  
          100% {
            opacity: 1;
          }
        }
  
        @keyframes fadeOutAnimation {
          0% {
            opacity: 1;
          }
  
          100% {
            opacity: 0;
          }
        }
      }
    }
  
    .meta-popup-selector__header-bottom {
      margin-bottom: 20px;
    }
  
    .meta-popup-selector__title {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 20px;
      line-height: 31px;
      color: #FFFFFF;
    }
  
    .meta-popup-selector__subtitle {
      font-family: 'Quicksand', sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.75);
    }
  
    .meta-popup-selector__content {
      @include custom-game-tabs-scrollbar;
      flex-grow: 1;
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
      padding: 0 8px 16px 8px;
      scrollbar-width: thin;
      scrollbar-color: rgba(0, 0, 0, 0.37) transparent;

      background-image: url('../../../assets/img/ellipse-small.svg');
      background-position: bottom;
      background-size: 28%;
      background-repeat: no-repeat;

      &::-webkit-scrollbar {
        height: 94px; /* Ancho del scrollbar */
      }
    
      &::-webkit-scrollbar-track {
        background: green; /* track del scrollbar */
      }
    
      &::-webkit-scrollbar-thumb {
        background-color: red ; /* botón del scrollbar */
        border-radius: 20px; /* Si quieres bordes redondeados */
        border: 3px solid green; /* Si quieres una barra de scroll con borde */
      }
    }
  
    .meta-popup-selector__grid {
      @include custom-game-tabs-scrollbar;
      width: 100%;
      align-content: center;
      margin-top: 18px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
  
      @media screen and (max-width: 1020px) {
        grid-template-columns: repeat(2, 1fr);
      }
  
      @media screen and (max-width: 760px) {
        grid-template-columns: 1fr;
      }
    }
  
    .meta-popup-selector__grid-item {
      display: flex;
      justify-content: center;
      height: 210px;
    }
  
    .meta-popup-selector__toast {
      position: absolute;
      bottom: 15px;
      left: 20px;
      z-index: 999;
    }

    .meta-popup-selector__button-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 50px;        

        .meta-popup-selector__button {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #5A34E1;
            ;
        
            display: inline-flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 24px;
        
            min-width: 164px;
            min-height: 39px;
            width: fit-content;
            margin: 0 auto;
        
            background: #FFFFFF;
            border-radius: 18px;
        
            cursor: pointer;
          }
    }

    .meta-popup-selector__button-container-intro {
      margin-top: 0;  
    }
  
    
  }
  