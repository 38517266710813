@use "sass:color";

* {
  box-sizing: border-box;
}

.email-form {
  width: 100%;
  margin: 0 auto;

  form {
    width: 100%;
    display: block;
    padding: 0;
    margin: 0 auto;
  }

  .form-group {
    margin-bottom: 32px;
  }

  .submit-control {
    margin-top: 0;
  }

  .form-title {
    font-family: 'Poppins', sans-serif;
    color: #1B1A26;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .form-text {
    font-family: 'Poppins', sans-serif;
    color: #3E3A68;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 32px;
  }


  .btn {
    @include poppins-font($size: 14px, $weight: 600);
    color: $white;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    border-radius: 8px;

    background-color: $primary-blueberry;
    transition: background-color 0.1s;

    width: 100%;
    height: auto;
    display: flex;
    font-size: 14px;


    &:hover {
      cursor: pointer;
      background-color: #8181fd;
    }

    &:active,
    &:target {
      box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.25) !important;
      position: relative;
      top: 4px;
    }

    &:disabled {
      pointer-events: none;
      border: none !important;
      color: $white !important;
      background-color: #acacf7 !important;
      box-shadow: none !important;
    }
  }

  .input-with-label input {
    margin: 0 auto 6px;
  }

  .form-input-error {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #FF3D30;
  }
}
