.meta-logout-modal__backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(28, 41, 43, 0.5);
  z-index: 100;
}

.meta-logout-modal {
  position: fixed;
  z-index: 101;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 567px;
  height: auto;
  border-radius: 8px;
  padding: 24px 40px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: var(--ui-bar-background);

  .meta-logout-modal__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .meta-logout-modal__header-icon {
    svg path {
      fill: var(--ui-modal-header-text-color)
    }
  }

  .meta-logout-modal__header-text {
    font-family: $primary-font;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: var(--ui-modal-header-text-color);
    margin-left: 9px;
  }

  .meta-logout-modal__content {
    font-family: $primary-font;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--ui-modal-content-text-color);
    margin: 6px 0 24px 0;
  }

  .meta-logout-modal__actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .meta-logout-modal__button {
    font-family: $primary-font;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 8px 24px;
    display: inline-block;
    min-width: 211px;
    text-align: center;
    cursor: pointer;
    color: #1C292B;
  }

  .meta-logout-modal__button--cancel {
    background: #FFAF22;
  }

  .meta-logout-modal__button--confirm {
    background: #C3D3DB;
  }

  .meta-logout-modal__button--warning {
    background: #ff6363;
    color: #ffffff;
  }

  .meta-logout-modal__dev-options {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 8px;
    padding: 16px 8px;
    border: 1px solid #ff6363;
    margin-top: 24px;
  }
}
