.reward-popup__backdrop {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
}

.reward-popup {
  width: 649px;
  padding: 18px 24px;
  border-radius: 0 8px 0 8px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--ui-bar-background);

  z-index: 101;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-left: 8px solid #FFAF22;
  border-right: 8px solid #FFAF22;

  @-webkit-keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  &.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }

  .reward-popup__top {
    width: 649px;
    height: 49px;
    background-image: url("../assets/popup_top_left.png"), url("../assets/popup_top_right.png");
    background-position: left top, right bottom;
    background-repeat: no-repeat;
    position: absolute;
    top: -33px;
    left: -8px;
  }

  .reward-popup__bottom {
    width: 649px;
    height: 49px;
    background-image: url("../assets/popup_bottom_left.png"), url("../assets/popup_bottom_right.png");
    background-position: left top, right bottom;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -33px;
    left: -8px;
  }

  .reward-popup__close-icon {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    z-index: 103;
    padding: 1.2em;
    margin: -1.2em;

    svg path {
      fill: var(--ui-modal-close-color);
    }
  }

  .reward-popup__title {
    font-family: Quicksand;
    font-size: 24px;
    font-weight: 700;
    color: var(--ui-modal-header-text-color);
    text-align: center;
    line-height: 30px;

    strong {
      color: #ffaf22
    }
  }

  .reward-popup__text {
    font-family: Quicksand;
    font-size: 18px;
    font-weight: 700;
    color: var(--ui-modal-header-text-color);
    text-align: center;
    line-height: 22px;

    strong {
      color: #ffaf22
    }
  }

  .reward-popup__image img {
    margin: 12px auto;
  }

  .reward-popup__center-image {
    margin: 0 auto;
  }

  .reward-popup__content {

    // deprecate
    p {
      font-family: Montserrat;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      text-align: left;
      color: var(--ui-modal-content-text-color);
    }

    .reward-popup__text-with-icon {
      display: inline-block;
      margin-top: 0;

      span {
        margin: 0 6px;

        vertical-align: middle;

        svg path {
          fill: var(--ui-modal-content-text-color);
        }
      }
    }
  }

  .reward-popup__button {
    min-width: 120px;
    width: max-content;
    height: 26px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    padding: 4px 32px;
    border-radius: 6px;
    background-color: #ffaf22;

    font-family: Quicksand, sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #1c292b;
    cursor: pointer
  }
}


// Battery status change animations

.battery-status-change__container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 128px;
}

.battery-status-change__image {
  position: absolute;
  user-select: none;
}

.flicker-in {
  animation: flicker-in-1 1.6s linear both;
}

.flicker-out {
  animation: flicker-out-1 1.6s linear both;
}

.vibrate {
  animation: vibrate-1 0.3s linear infinite both;
}

.pulsate-fwd {
  animation: pulsate-fwd 0.3s ease-in-out 2 both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-12-1 11:34:9
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * @animation flicker-in-1
 * ----------------------------------------
 */
@keyframes flicker-in-1 {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  10.1% {
    opacity: 1;
  }

  10.2% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  20.1% {
    opacity: 1;
  }

  20.6% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  30.1% {
    opacity: 1;
  }

  30.5% {
    opacity: 1;
  }

  30.6% {
    opacity: 0;
  }

  45% {
    opacity: 0;
  }

  45.1% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  55% {
    opacity: 1;
  }

  55.1% {
    opacity: 0;
  }

  57% {
    opacity: 0;
  }

  57.1% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  60.1% {
    opacity: 0;
  }

  65% {
    opacity: 0;
  }

  65.1% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  75.1% {
    opacity: 0;
  }

  77% {
    opacity: 0;
  }

  77.1% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  85.1% {
    opacity: 0;
  }

  86% {
    opacity: 0;
  }

  86.1% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}


/**
 * ----------------------------------------
 * animation flicker-out-1
 * ----------------------------------------
 */
@keyframes flicker-out-1 {
  0% {
    opacity: 1;
  }

  13.9% {
    opacity: 1;
  }

  14% {
    opacity: 0;
    box-shadow: none;
  }

  14.9% {
    opacity: 0;
    box-shadow: none;
  }

  15% {
    opacity: 1;
  }

  22.9% {
    opacity: 1;
  }

  23% {
    opacity: 0;
    box-shadow: none;
  }

  24.9% {
    opacity: 0;
    box-shadow: none;
  }

  25% {
    opacity: 1;
  }

  34.9% {
    opacity: 1;
  }

  35% {
    opacity: 0;
    box-shadow: none;
  }

  39.9% {
    opacity: 0;
    box-shadow: none;
  }

  40% {
    opacity: 1;
  }

  42.9% {
    opacity: 1;
  }

  43% {
    opacity: 0;
    box-shadow: none;
  }

  44.9% {
    opacity: 0;
    box-shadow: none;
  }

  45% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  54.9% {
    opacity: 1;
  }

  55% {
    opacity: 0;
    box-shadow: none;
  }

  69.4% {
    opacity: 0;
    box-shadow: none;
  }

  69.5% {
    opacity: 1;
  }

  69.9% {
    opacity: 1;
  }

  70% {
    opacity: 0;
    box-shadow: none;
  }

  79.4% {
    opacity: 0;
    box-shadow: none;
  }

  79.9% {
    opacity: 1;
  }

  80% {
    opacity: 0;
    box-shadow: none;
  }

  89.8% {
    opacity: 0;
    box-shadow: none;
  }

  89.9% {
    opacity: 1;
    box-shadow: none;
  }

  90% {
    opacity: 0;
    box-shadow: none;
  }

  100% {
    opacity: 0;
  }
}


/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
@keyframes pulsate-fwd {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}



/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
@keyframes vibrate-1 {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translate(-2px, 2px);
  }

  40% {
    transform: translate(-2px, -2px);
  }

  60% {
    transform: translate(2px, 2px);
  }

  80% {
    transform: translate(2px, -2px);
  }

  100% {
    transform: translate(0);
  }
}
