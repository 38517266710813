.popup-manager {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
  z-index: 102;
}

.popup-manager-transparent {
  background-color: transparent;
}
