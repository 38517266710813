.lobby-teacher-practice-page {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(
    --Linear-progress-dark,
    linear-gradient(180deg, #3a1587 9%, #490dc8 99.99%, #3703a4 100%)
  );
  z-index: 1002;
  animation: fadeIn 0.5s;
  background-image: url("./assets/backgroundLobby.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .container-countdown {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    min-height: 100vh;
    text-shadow: 0px 7px 27px rgba(0, 0, 0, 0.5);

    .text-countdown {
      @include inter-font($size: 200px, $weight: 700);
      color: #ffd600;
      line-height: 25px;
      word-wrap: break-word;
      text-align: center;
      z-index: 103;
      animation: fadeIn 0.5s;
    }
    .text-countdown-end {
      @include inter-font($size: 155px, $weight: 700);
      color: #ffd600;
      line-height: 200px;
      word-wrap: break-word;
      text-align: center;
      z-index: 103;
      animation: fadeIn 0.5s;
    }
  }

  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    align-self: center;
    align-content: center;
    height: 100vh;
    margin-top: 11vh;
    @media (max-height: $breakpoint-tablet) {
      margin-top: 2vh;
    }

    &.center {
      justify-content: center;
    }

    .container-content {
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: center;
      width: 98%;
      height: 70vh;
      @media (min-width: $breakpoint-desktop-large) {
        margin-top: 64px;
      }
      @media (max-height: $breakpoint-tablet) {
        margin-top: 8px;
      }
    }

    .container-content-full {
      @media (min-width: $breakpoint-desktop-large) {
        // margin-top: 80px;
      }      
    }

    .message-container-title {
      width: 489px;
      height: 41px;
      padding-left: 37px;
      padding-right: 37px;
      padding-top: 16px;
      padding-bottom: 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      display: inline-flex;
      z-index: 105;
      margin-top: 70px;
      margin-bottom: 120px;
    }

    .message-container {
      width: auto;
      height: 41px;
      padding-left: 37px;
      padding-right: 37px;
      padding-top: 16px;
      padding-bottom: 16px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 39px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      display: inline-flex;
      z-index: 105;
      margin-bottom: 70px;
      margin-top: 6%;
      min-width: 489px;
      @media (max-height: $breakpoint-tablet) {
        margin-bottom: 38px;
        margin-top: 3%;
      }
    }

    .message-container-full {
      margin-top: 3.5%;
      @media (max-height: $breakpoint-tablet) {
        margin-bottom: 38px;
        margin-top: 0%;
      }
    }

    .text-title {
      @include poppins-font($size: 28px, $weight: 700);
      color: white;
      line-height: 25px;
      word-wrap: break-word;
    }

    .text {
      @include inter-font($size: 18px, $weight: 700);
      color: white;
      line-height: 25px;
      word-wrap: break-word;
    }

    .players-container {
      display: flex;
      gap: 1em;
      flex-wrap: wrap;
      z-index: 105;
      justify-content: center;
      align-items: end;
      width: 100%;
      margin-bottom: 28px;

      .player-card {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 36px;
        align-self: flex-start;

        .background-avatar {
          margin-bottom: 12px;
          
          &.lobby-player-online {
            animation: pulseLobby 2s infinite;
          }

          .pompa {
            position: absolute;
            width: 137px;
            transform: translate(-23px, -27px) scale(1);
          }

          .pompa-full {
            position: relative;
            width: 85px;
            transform: translate(0px, 0px) scale(1);
          }

          @keyframes pulseLobby {
            0% {
              transform: scale(0.95);
            }

            70% {
              transform: scale(1);
            }

            100% {
              transform: scale(0.95);
            }
          }

          .container-avatar {
            
            .player-avatar {
              width: 90px;
              height: 90px;
              position: relative;
              transform: translate(0, 2px);
            }
            
          }

          .container-avatar-full {
            transform: translate(14px, -59px);
            .player-avatar-full {
              width: 55px;
              height: 55px;
              position: absolute;
              top: 0;
              transform: translate(1px, -5px);
            }
          }
        }

        .player-name {
          width: auto;
          margin-top: 1em;
          color: #ffffff;
          padding: 5px;
          background: rgba(0, 0, 0, 0.4);
          border-radius: 8px;

          span {
            @include inter-font($size: 18px, $weight: 500);
            line-height: 25px;
            @media (max-height: $breakpoint-tablet) {
              @include inter-font($size: 12px, $weight: 500);
            }
          }
        }

        .player-name-full {
          margin-top: 0;
          span {
            @include inter-font($size: 12px, $weight: 500);
            line-height: 25px;
            @media (max-height: $breakpoint-tablet) {
              @include inter-font($size: 12px, $weight: 500);
            }
          }
        }
      }

      .player-card-full {
        margin-top: 20px;
        margin-left: 6px;
      }

      .blocked {
        opacity: 0.2;

        .pompa {
          animation: none !important;
        }
      }
    }

    .players-container {
      margin-bottom: 0px;
    }

    .container-block-number-players {
      width: 402px;
      height: 143px;
      position: relative;
      background: #683fac;
      box-shadow: 0px 2px 0px #9940c3 inset;
      border-radius: 8px;
      overflow: hidden;
      z-index: 102;
      display: flex;
      justify-content: center;
      align-self: center;
      flex-direction: column;
      align-items: center;
      background: radial-gradient(
        ellipse at center bottom,
        #a948d7 0%,
        #683fac 64%
      );

      .container-malla {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        position: absolute;
        top: 20px;
      }

      .container-number-players {
        width: auto;
        padding: 5.41px 18px;
        position: relative;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        gap: 10.81px;
        display: inline-flex;
        height: 41px;
        margin-top: 26px;

        .container-number-players-child {
          color: white;
          line-height: 27.03px;
          @include inter-font($size: 16.46px, $weight: 700);
        }
      }

      .waiting-players {
        position: relative;
        color: $white;
        @include poppins-font($size: 22px, $weight: 700);
        margin-top: 31px;
      }
    }
  }

  .main-container-full{
    margin-top: 7vh;
    @media (max-height: $breakpoint-tablet) {
      margin-top: 2vh;
    }
  }

  .container-fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;

    .container-fullscreen-child {
      display: flex;
      justify-content: right;
      align-items: center;
      z-index: 1000;

      .container-fullscreen-button {
        color: white;
        border: 1px #fff solid;
        padding: 3px;
        border-radius: 36px;
        font-size: 16px;
        cursor: pointer;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
        transition: all 0.3s ease;
        margin-top: 10px;
        margin-right: 10px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background-color: rgb(0 0 0 / 32%);
          transform: scale(1.05);
        }

        &:active,
        &:focus {
          background-color: transparent;
          transform: scale(1);
        }

        img {
          width: 16px;
          height: 16px;
          color: white;
        }
      }
    }
  }
}
@media screen and (orientation: portrait) {
  /* .lobby-teacher-practice-page::before {
    content: "Por favor, cambia a la vista horizontal para utilizar esta app.";
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: rgba(0,0,0,0.6);
    color: white;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 2em;
    transform: rotate(-90deg);
    transform-origin: center center;
    z-index: 9999;
  } */

  .lobby-teacher-practice-page {
    transform: rotate(90deg);
    width: 100vh;
  }
}
