.shop-coins-counter {
  border-radius: 12px;
  background: #243041;
  width: auto;
  height: 36px;
  padding: 6px 4px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;

  .shop-coins-counter__text {
    color: #FFF;
    text-align: right;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    font-family: Quicksand, sans-serif;
    font-size: 18px;
    font-weight: 700;
    padding: 0 4px 0 6px;
  }

  .shop-coins-counter__img img {
    margin-top: 3px;
  }
}

.shop-coins-counter--default {
  background: #243041;

}

.shop-coins-counter--available {
  background: #243041;
  border: 2px solid #576B70; 
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.10);
}

.shop-coins-counter--locked {
  border: 2px solid #838383;
  background: #A1A1A1;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.10);
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.shop-coins-counter--expensive {
  border: 2px solid #B94141;
  background: #EB5656;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.10);

}