.meta-ranking {

  .meta-ranking__title {
    font-family: 'Quicksand';
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: var(--ui-primary-color);
    ;
  }

  .meta-ranking__content {
    background: #88A7B8;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 34px;
    margin: 8px 0;
    flex-direction: column;
    padding: 9px;
  }

  .meta-ranking__scroll-wrapper {
    @include custom-thin-scrollbar;
    max-height: 320px;
    overflow-y: auto;
    width: 100%;
  }

  .meta-ranking__button {
    color: var(--ui-ranking-more-color);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    text-align: right;
    cursor: pointer
  }
}
