.item-detail-pet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  // width: 154px;
  width: auto;
  height: auto;
  // height: 306px;
  cursor: pointer;

  background-image: url('../../../../assets/img/pompa.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  &:hover {
    .item-detail-pet__wrapper {
      // box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.50);
    }
  }

  .item-detail-pet__wrapper {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
  }

  

  // .item-detail-pet__wrapper--locked {
  //   background: #D8D8D8;
  // }

  .item-detail-pet__check {
    position: absolute;
    right: 13px;
    bottom: 11px;
    z-index: 3;
    height: 30px;
    width: 30px;
  }

  .item-detail-pet__header {
    position: absolute;
    top: 8px;
    z-index: 2;
    width: 200px;
    height: 36px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }


  .item-detail-pet__image {
    height: 140px;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 20px;
    position: relative; 

    img {
      width: 100%;
      height: auto;
    }

    .item-detail-pet__locked {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  .item-detail-pet__image-l{
    height: 160px;
    width: 160px;
  }

  .item-detail-pet__image-m{
    height: 140px;
    width: 140px;    
  }

  .item-detail-pet__image-s{
    height: 120px;
    width: 120px;
    img {
      width: 90%;
      height: 90%;
    }
  }

  .item-detail-pet__image-xs{
    height: 90px;
    width: 90px;
  }
  
  .item-detail-pet__image--locked {
    background: transparent;
    border: none;
  }
 
}

.item-detail-pet-selected {
  background-image: url('../../../../assets/img/pompa_select.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.item-detail-pet__button {
  display: flex;
  height: 36px;
  padding: 8px 16px;
  align-items: center;
  color: #1C292B;
  text-align: center;
  font-family: Quicksand;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.item-detail-pet__button--default {
  border-radius: 12px;
  border: 2px solid #576B70;
  background: #FFF;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.10);
}

.item-detail-pet__button--available {
  border-radius: 40px;
  border: none;
  background: #FFAF22;
  box-shadow: none;
}

.item-detail-pet__button--disabled {
  border-radius: 40px;
  border: none;
  background: #F2F2F2;
  box-shadow: none;
  pointer-events: none;
}

.swiper-slide-prev, .swiper-slide-next {
  .item-detail-pet__image {
    height: 100px;
    width: 100px;    
  }
}

.item-detail-pet__content-item-detail-pet {
  position: relative;
}

.item-detail-pet__content-level{
  //height: 56px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  .item-detail-pet__content-level-text {
    color: #FFFFFF;
    background-color: #3703A4;
    padding: 6px 12px;
    border-radius: 18px 0 0 18px;
    margin-left: -20px;
    font-family: Poppins, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
  }
  .map-level-counter {
    position: absolute;
    right: -27px;
    .map-level-counter-text{
      top: -6px;
    }
  }
}

.item-detail-pet__content-header {
  display: inline-flex;
  width: 100%;
  position: absolute;
  justify-content: center;
}

.item-detail-pet__content__content-coins{
  //height: 56px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;

  .item-detail-pet__content__content-coins-text {
    color: #FFFFFF;
    background-color: #3703A4;
    padding: 6px 15px;
    border-radius: 18px 0 0 18px;
    margin-left: 40px;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
  }

  .item-detail-pet__content__content-coins-icon {
    position: absolute;
    right: -27px;
    top: -4px;

    svg {
      height: 42px;
      width: 42px;
    }
  } 
  
}

.item-detail-pet-img_disabled {
  filter: brightness(0) contrast(0.7);
}