.avatar-card-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  // width: 154px;
  width: auto;
  height: auto;
  // height: 306px;

  &:hover {
    .avatar-card-slider__wrapper {
      // box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.50);
    }
  }

  .avatar-card-slider__wrapper {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
  }

  

  // .avatar-card-slider__wrapper--locked {
  //   background: #D8D8D8;
  // }

  .avatar-card-slider__check {
    display: none;
  }

  .avatar-card-slider__header {
    position: absolute;
    top: 8px;
    z-index: 2;
    width: 200px;
    height: 36px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }


  .avatar-card-slider__image {
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    position: relative; 

    @media screen and (max-width: 780px) {
      height: 70px;
      width: 70px; 
    }

    @media screen and (max-width: 570px) {
      height: 50px;
      width: 50px; 
    }

    img {
      width: 100%;
      height: auto;
    }

    .avatar-card-slider__locked {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  
  .avatar-card-slider__image--locked {
    background: transparent;
    border: none;
  }
 
}

.avatar-card-slider__button {
  display: flex;
  height: 36px;
  padding: 8px 16px;
  align-items: center;
  color: #1C292B;
  text-align: center;
  font-family: Quicksand;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.avatar-card-slider__button--default {
  border-radius: 12px;
  border: 2px solid #576B70;
  background: #FFF;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.10);
}

.avatar-card-slider__button--available {
  border-radius: 40px;
  border: none;
  background: #FFAF22;
  box-shadow: none;
}

.avatar-card-slider__button--disabled {
  border-radius: 40px;
  border: none;
  background: #F2F2F2;
  box-shadow: none;
  pointer-events: none;
}

.swiper-slide-prev, .swiper-slide-next {
  .avatar-card-slider__image {
    height: 100px;
    width: 100px;    
    @media screen and (max-width: 780px) {
      height: 90px;
      width: 90px; 
    }
    @media screen and (max-width: 570px) {
      height: 70px;
      width: 70px; 
    }
  }
}

.swiper-slide-active {
  .avatar-card-slider__image {
    height: 150px;
    width: 150px; 
    margin: 10px;   
    @media screen and (max-width: 780px) {
      height: 120px;
      width: 120px; 
    }
    @media screen and (max-width: 570px) {
      height: 100px;
      width: 100px; 
    }
  } 
  .selected-slider {
    background-image: url('../../../../../assets/img/pompa_select.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    animation: pulseAvatar 2s infinite;
  } 

  .no-selected-slider {
    background-image: url('../../../../../assets/img/pompa.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    animation: pulseAvatar 2s infinite;
  }

  .avatar-card-slider__check {
    display: block;
    position: absolute;
    right: 13px;
    bottom: 12px;
    z-index: 3;
    height: 30px;
    width: 30px;
  }
  
}

/*.avatar-swiper-intro {
  .swiper-slide-active {
    background-image: url('../../../../../assets/img/pompa.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    animation: pulseAvatar 2s infinite;
  }
}*/

@keyframes pulseAvatar{
  0% {
    transform: scale(0.95);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.95);
  }
}
